import React from "react";
import PropTypes from "prop-types";
import Radio from "components/Radio";

const Compliance = ({ isCompliant = null, setIsCompliant, disabled }) => {
  return (
    <div className="compliance">
      <div className="compliance__title"> Livraison conforme</div>{" "}
      <div className="compliance__content">
        <Radio
          disabled={disabled}
          label="Oui"
          checked={isCompliant === true}
          onChange={() => (disabled ? () => {} : setIsCompliant(true))}
        />
        <Radio
          disabled={disabled}
          label="Non"
          checked={isCompliant === false}
          onChange={() => (disabled ? () => {} : setIsCompliant(false))}
        />
      </div>
    </div>
  );
};

Compliance.propTypes = {
  isCompliant: PropTypes.bool,
  setIsCompliant: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Compliance;
