import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const Accordion = ({
  title,
  content,
  activeItem,
  setActiveItem,
  index,
  headerClassName,
}) => {
  const isActive = index === activeItem;

  const accordionContentClasses = classnames("accordion__content", {
    "accordion__content--visible": isActive,
  });

  return (
    <div className="accordion">
      <div
        className={classnames("accordion__header", {
          [headerClassName]: headerClassName,
        })}
        onClick={() => {
          isActive ? setActiveItem(null) : setActiveItem(index);
        }}
      >
        {title}
        <i className={`fa fa-chevron-${isActive ? "down" : "right"}`} />
      </div>
      <div className={accordionContentClasses}>{content}</div>
    </div>
  );
};

Accordion.propTypes = {
  activeItem: PropTypes.string,
  content: PropTypes.node.isRequired,
  index: PropTypes.string.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  headerClassName: PropTypes.string,
};

export default Accordion;
