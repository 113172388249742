import { gql } from "@apollo/client";
import { ROADMAP_FRAGMENT } from "pages/Round/roadmapQuery";

export const VALIDATE_ORDER = gql`
  ${ROADMAP_FRAGMENT}

  mutation validateOrder(
    $code: ID!
    $orderId: ID!
    $delivery: ValidateDeliveryInput!
  ) {
    validateOrder(code: $code, orderId: $orderId, delivery: $delivery) {
      success
      message
      roadmap {
        ...RoadmapFragment
      }
    }
  }
`;

export const makeValidateOrderOptions = (roadmap, orderId, delivery) => ({
  variables: {
    code: roadmap.code,
    orderId,
    delivery,
  },
  context: {
    offline: true,
  },
  optimisticResponse: {
    validateOrder: {
      __typename: "ValidateOrderMutationResponse",
      success: true,
      roadmap: {
        __typename: "Roadmap",
        id: roadmap.id,
        am: roadmap.am.map((item) => {
          if (item.__typename === "RoadmapOrder") {
            return {
              __typename: "RoadmapOrder",
              id: item.id,
              isDelayed: item.order.id === orderId,
              delivered:
                item.order.id === orderId ? delivery.delivered : item.delivered,
            };
          }

          return item;
        }),
        pm: roadmap.pm.map((item) => {
          if (item.__typename === "RoadmapOrder") {
            return {
              __typename: "RoadmapOrder",
              id: item.id,
              isDelayed: item.order.id === orderId,
              delivered:
                item.order.id === orderId ? delivery.delivered : item.delivered,
            };
          }

          return item;
        }),
      },
    },
  },
});
