import { object, string, boolean } from "yup";
import {
  isDisplayedPluggedByClient,
  isDisplayedGasCookingSecurityCheck,
  isDisplayedSecurity,
} from "../helpers/securityCheck";

export const qualitySheetSchema = (bypass) => {
  const defaultValidation = {
    nombre_colis: string().required("Vous devez indiquer un nombre de colis"),
    isCompliant: boolean()
      .nullable()
      .required("Vous devez indiquer si la marchandise est conforme"),
    bypass: object().shape({
      reponse: boolean().notRequired(),
      motif: string().when("reponse", {
        is: true,
        then: string().required("Vous devez indiquer un motif"),
        otherwise: string().notRequired(),
      }),
    }),
  };

  const completeValidation = {
    ...defaultValidation,
    intervention: object().shape({
      controle_emballage: boolean()
        .nullable()
        .required("Évaluation requise : Contrôle emballage"),
      deballage_effectue: boolean()
        .nullable()
        .required("Évaluation requise : Déballage effectué"),
      reprise_emballage: boolean()
        .nullable()
        .required("Évaluation requise : Reprise emballage"),
    }),
    decharge_responsabilite: object().shape({
      reponse: boolean().notRequired(),
      nom: string().when("reponse", {
        is: true,
        then: string().required(
          "Si vous cochez 'Décharge de responsabilité' vous devez indiquer votre nom."
        ),
        otherwise: string().notRequired(),
      }),
      produits: string().when("reponse", {
        is: true,
        then: string().required(
          "Si vous cochez 'Décharge de responsabilité' une liste de produits est requise"
        ),
        otherwise: string().notRequired(),
      }),
    }),
    non_installation: object().shape({
      reponse: boolean().notRequired(),
      motif: string().when("reponse", {
        is: true,
        then: string().required("Vous devez indiquer un motif"),
        otherwise: string().notRequired(),
      }),
    }),
    security: object().when(
      ["non_installation", "$articleCategories"],
      (non_installation, articleCategories, schema) => {
        if (!isDisplayedSecurity(articleCategories, non_installation.reponse)) {
          return schema;
        }

        const gazCookingSchema = {
          changement_gicleurs: object().shape({
            gicleur_remplace_par_technicien: string()
              .nullable()
              .required(
                "Évaluation requise : Les gicleurs d’origine ont été remplacés par le technicien"
              ),
          }),
          appareils_deux_arrivees: object().shape({
            bouchon_laiton_sur_arrivee_non_utilisee: string()
              .nullable()
              .required(
                "Évaluation  : Mise en place du bouchon laiton sur l’arrivée non utilisée"
              )
              .notOneOf(["Non"], null),
            joint_fibre_remplace_si_bouchon_deplace: string()
              .nullable()
              .when("bouchon_laiton_sur_arrivee_non_utilisee", {
                is: "Oui",
                then: string()
                  .nullable()
                  .required(
                    "Évaluation requise : Si le bouchon a été déplacé d’une arrivée à l’autre, le joint fibre a-t-il été remplacé"
                  )
                  .notOneOf(
                    ["Non"],
                    "La valeur 'Non' n'est pas autorisée pour le champ : Si le bouchon a été déplacé d’une arrivée à l’autre, le joint fibre a-t-il été remplacé"
                  ),
                otherwise: string().nullable().notRequired(),
              }),
          }),
          controle_etancheite: object().when(
            "appareils_deux_arrivees",
            (
              {
                bouchon_laiton_sur_arrivee_non_utilisee,
                joint_fibre_remplace_si_bouchon_deplace,
              },
              schema
            ) => {
              const tuyau_gaz = string()
                .nullable()
                .required(
                  "Évaluation requise : Contrôle de l'étanchéité au niveau du tuyau de gaz"
                )
                .notOneOf(["Non"], null);

              const bouchon_laiton = string()
                .nullable()
                .required(
                  "Évaluation requise : Contrôle de l'étanchéité au niveau du bouchon laiton"
                )
                .notOneOf(["Non"], null);

              if (
                bouchon_laiton_sur_arrivee_non_utilisee ===
                "Pas de deuxième arrivée"
              ) {
                return schema.shape({ tuyau_gaz });
              }

              if (
                bouchon_laiton_sur_arrivee_non_utilisee ===
                  "Le bouchon était déjà en place" ||
                (bouchon_laiton_sur_arrivee_non_utilisee === "Oui" &&
                  [
                    "Oui",
                    "Le bouchon était dans le sachet d’accessoires",
                  ].includes(joint_fibre_remplace_si_bouchon_deplace))
              ) {
                return schema.shape({
                  tuyau_gaz,
                  bouchon_laiton,
                });
              }

              return schema;
            }
          ),
        };

        const pluggedByClientSchema = {
          controle_branchement: object().shape({
            controle_branchement_par_client: string()
              .nullable()
              .required(
                "Évaluation requise : Contrôle du branchement de l'appareil par le client"
              ),
          }),
        };

        return schema.shape({
          ...(isDisplayedPluggedByClient(articleCategories) &&
            pluggedByClientSchema),
          ...(isDisplayedGasCookingSecurityCheck(articleCategories) &&
            gazCookingSchema),
        });
      }
    ),
    signature: string().nullable().required("Votre signature est requise"),
    confirmation: boolean().oneOf(
      [true],
      "Merci d'accepter nos conditons en cochant la case ci-dessous"
    ),
  };

  return object().shape(bypass ? defaultValidation : completeValidation);
};
