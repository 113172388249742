import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";
import Checkbox from "rc-checkbox";

import {
  RETURN_ARTICLES,
  makeStartReturnArticles,
} from "./ReturnedArticleMutation";

const ReturnedArticleModal = ({
  closeModal,
  order,
  setOrderReturned,
  itemId,
}) => {
  const [formValues, setFormValues] = useState(
    order.articles.map((article) => ({
      name: article.article.name,
      checked: false,
      quantity: article.quantity,
    }))
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [returnArticles, { error, loading }] = useMutation(RETURN_ARTICLES, {
    onCompleted: ({ returnArticles }) => {
      if (returnArticles?.success) {
        setOrderReturned(itemId);
        closeModal();
      } else {
        setErrorMessage(returnArticles.message);
      }
    },
  });

  const updateFormValues = (name, index, value) => {
    setFormValues((formValues) => {
      return formValues.map((formValue, i) => {
        if (i !== index) {
          return formValue;
        }

        return {
          ...formValue,
          [name]: value,
        };
      });
    });
  };

  if (error) {
    return <Redirect to="/" />;
  }

  return (
    <form
      id="returnedArticlesForm"
      onSubmit={(e) => {
        e.preventDefault();

        returnArticles(makeStartReturnArticles(order.id, formValues));
      }}
    >
      <div className="modal returned-articles">
        <header className="header">
          <div className="row">
            <div className="column">
              <h2>Liste des produits directement retournés en magasin</h2>
            </div>
          </div>
        </header>

        <div className="contain container">
          <div className="row">
            <div className="column returned-articles__list">
              <h3 className="alert--info">
                Indiquer ici les articles que vous avez directement retournés au
                magasin sans passer par le dépôt.
              </h3>
              {order.articles.map(({ article }, i) => {
                return (
                  <div className="returned-article" key={article.id}>
                    <div className="returned-article__article-check">
                      <label>
                        <Checkbox
                          name={`article[${i}].checked`}
                          value={formValues[i].checked}
                          onChange={(e) =>
                            updateFormValues("checked", i, e.target.checked)
                          }
                        />
                        <span>{article.name}</span>
                      </label>
                    </div>
                    <div className="returned-article__article-number">
                      <label
                        className="returned-article__article-number-label"
                        htmlFor="article-number"
                      >
                        Nombre d&apos;article
                      </label>
                      <input
                        id="article-number"
                        name={`article[${i}].number`}
                        type="number"
                        value={formValues[i].quantity}
                        onChange={(e) =>
                          updateFormValues(
                            "quantity",
                            i,
                            parseInt(e.target.value)
                          )
                        }
                        min="0"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {errorMessage && (
          <p className="_text-error">
            Impossible de sauvegarder la liste des produits directement
            retournés en magasin
          </p>
        )}

        <footer className="footer-section">
          <div className="row">
            <div className="column">
              <button className="button" onClick={closeModal}>
                Annuler
              </button>
              <button className="button" type="submit">
                {loading ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                ) : (
                  "Valider"
                )}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </form>
  );
};

ReturnedArticleModal.propTypes = {
  itemId: PropTypes.string.isRequired,
  setOrderReturned: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        article: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
        quantity: PropTypes.number.isRequired,
        drop_article: PropTypes.bool.isRequired,
        direct_drop_article: PropTypes.bool.isRequired,
        install_article: PropTypes.bool.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default ReturnedArticleModal;
