import React from "react";
import { Prompt } from "react-router";
import PropTypes from "prop-types";

const AskBeforeLeaving = ({
  shouldAsk,
  shouldBlockRefreshOrClosing,
  message,
}) => {
  const mounted = React.useRef();

  /**
   * Prompt will block any routing, but not page refresh or closing.
   * To block that, we need to add this useEffect.
   * Note that it is not possible to customize this message for security reasons
   * https://stackoverflow.com/questions/37782104/javascript-onbeforeunload-not-showing-custom-message
   */
  React.useEffect(() => {
    if (!shouldBlockRefreshOrClosing) return;
    if (!mounted.current) {
      mounted.current = true;
    } else if (shouldAsk) {
      window.onbeforeunload = () => true;
    }
    /**
     * Make sure we clean the window.onbeforeunload property when our component unmounts
     * to prevent undesired behaviors in the others pages.
     */
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldAsk, shouldBlockRefreshOrClosing]);

  return <Prompt when={shouldAsk} message={message} />;
};

AskBeforeLeaving.propTypes = {
  shouldAsk: PropTypes.bool,
  shouldBlockRefreshOrClosing: PropTypes.bool,
  message: PropTypes.string,
};

AskBeforeLeaving.defaultProps = {
  shouldAsk: true,
  shouldBlockRefreshOrClosing: true,
  message: "Êtes-vous sûr de vouloir quitter cette page ?",
};

export default AskBeforeLeaving;
