import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FailureReasonSelector from "./FailureReasonSelector";

const DeliveryArticleSwitch = ({
  disabled,
  /* This attribute is only used to visually "disable" the switch */
  visuallyDisabled,
  checked,
  onChange,
}) => {
  return (
    <label
      className={classNames("delivery-article-switch", {
        "delivery-article-switch--disabled": disabled || visuallyDisabled,
      })}
    >
      <input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={(ev) => {
          onChange(ev.target.checked);
        }}
      />
      <span className="slider">
        <i className="fa fa-check" />
        <i className="fa fa-minus" />
      </span>
    </label>
  );
};

DeliveryArticleSwitch.propTypes = {
  disabled: PropTypes.bool,
  visuallyDisabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DeliveryOrderArticle = ({
  disabled,
  key,
  index,
  articleName,
  articleMetadata,
  metadataId,
  setOrderArticleMetadataDeliveryStatus,
  handleChangeFailureReason,
}) => {
  return (
    <div key={key} className="delivery-order-article">
      <div className="delivery-order-article__left-container">
        <div className="delivery-order-article__index">{`#${index + 1}`}</div>
        <DeliveryArticleSwitch
          disabled={disabled}
          checked={articleMetadata.is_delivered}
          onChange={setOrderArticleMetadataDeliveryStatus}
        />
        <div className="delivery-order-article__name">{articleName}</div>
      </div>
      {!articleMetadata.is_delivered && (
        <FailureReasonSelector
          disabled={disabled}
          value={articleMetadata.failure_reason}
          handleChangeFailureReason={handleChangeFailureReason}
          metadataId={metadataId}
        />
      )}
    </div>
  );
};

DeliveryOrderArticle.propTypes = {
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  articleName: PropTypes.string.isRequired,
  metadataId: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  articleMetadata: PropTypes.shape({
    is_delivered: PropTypes.bool.isRequired,
    failure_reason: PropTypes.string,
  }).isRequired,
  setOrderArticleMetadataDeliveryStatus: PropTypes.func.isRequired,
  handleChangeFailureReason: PropTypes.func.isRequired,
};

const DeliveryArticle = ({
  isValidated,
  orderArticle,
  isDeliveryFailed,
  setOrderArticleDeliveryStatus,
  setOrderArticleFailureReason,
  setOrderArticleMetadataDeliveryStatus,
  setOrderArticleMetadataFailureReason,
}) => {
  const [accordionOpen, setAccordionOpen] = React.useState(isValidated);

  const handleChangeFailureReason = (failureReason, metadataId) => {
    if (metadataId) {
      setOrderArticleMetadataFailureReason(
        orderArticle.id,
        metadataId,
        failureReason
      );
    } else {
      setOrderArticleFailureReason(orderArticle.id, failureReason);
    }
  };
  const color = orderArticle.has_heavy_package ? "red" : "inherit";
  return (
    <div className="delivery-article">
      <div className="delivery-article-accordion">
        <div className="delivery-article-accordion-header">
          <div className="delivery-article-accordion-header__content">
            <div className="delivery-article-accordion-header__left-container">
              {!isValidated && (
                <DeliveryArticleSwitch
                  disabled={isDeliveryFailed}
                  visuallyDisabled={orderArticle.metadata.some((metadatum) => {
                    return metadatum.is_delivered !== orderArticle.isDelivered;
                  })}
                  checked={orderArticle.isDelivered}
                  onChange={(isDelivered) =>
                    setOrderArticleDeliveryStatus(orderArticle.id, isDelivered)
                  }
                />
              )}
              <div className="delivery-article__informations">
                <div className="delivery-article__name">
                  {orderArticle.article.name}
                </div>
                <div className="delivery-article__options">
                  <span>{`Qté : ${orderArticle.quantity}`}</span>
                  <br />
                  {orderArticle.weight ? (
                    <>
                      <span style={{ color: color }}>
                        {`Poids : ${orderArticle.weight} kg`}
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {orderArticle.number_of_packages ? (
                    <>
                      <span>{`Nombre de colis : ${orderArticle.number_of_packages}`}</span>

                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {orderArticle.direct_drop_article && (
                    <span> Dépose au pied du camion </span>
                  )}
                  {orderArticle.drop_article && (
                    <span>Dépose dans la pièce</span>
                  )}
                  {orderArticle.install_article && (
                    <span>Installation/Montage</span>
                  )}
                </div>
              </div>
            </div>
            <div className="delivery-article-accordion-header__right-container">
              {!isDeliveryFailed &&
                !orderArticle.isDelivered &&
                !isValidated && (
                  <FailureReasonSelector
                    value={orderArticle.failureReason}
                    handleChangeFailureReason={handleChangeFailureReason}
                  />
                )}
            </div>
          </div>
          {!isDeliveryFailed && (
            <div
              className="delivery-article-accordion-header__action"
              onClick={() => setAccordionOpen(!accordionOpen)}
            >
              {accordionOpen ? (
                <i className="fas fa-chevron-down fa-2x"></i>
              ) : (
                <i className="fas fa-chevron-right fa-2x"></i>
              )}
            </div>
          )}
        </div>
        {accordionOpen && (
          <div className="delivery-article-accordion-content">
            {!isDeliveryFailed &&
              orderArticle.metadata.map((articleMetadata, index) => {
                return (
                  <DeliveryOrderArticle
                    key={articleMetadata.metadata_id}
                    metadataId={articleMetadata.metadata_id}
                    disabled={isValidated}
                    index={index}
                    articleName={orderArticle.article.name}
                    articleMetadata={articleMetadata}
                    setOrderArticleMetadataDeliveryStatus={(isDelivered) =>
                      setOrderArticleMetadataDeliveryStatus(
                        orderArticle.id,
                        articleMetadata.metadata_id,
                        isDelivered
                      )
                    }
                    handleChangeFailureReason={handleChangeFailureReason}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

DeliveryArticle.propTypes = {
  isValidated: PropTypes.bool.isRequired,
  orderArticle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    article: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    quantity: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    number_of_packages: PropTypes.number.isRequired,
    has_heavy_package: PropTypes.bool.isRequired,
    drop_article: PropTypes.bool.isRequired,
    direct_drop_article: PropTypes.bool.isRequired,
    install_article: PropTypes.bool.isRequired,
    failureReason: PropTypes.string,
    isDelivered: PropTypes.bool.isRequired,
    metadata: PropTypes.arrayOf(
      PropTypes.shape({
        is_delivered: PropTypes.bool.isRequired,
        failure_reason: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  setOrderArticleDeliveryStatus: PropTypes.func.isRequired,
  setOrderArticleFailureReason: PropTypes.func.isRequired,
  setOrderArticleMetadataDeliveryStatus: PropTypes.func.isRequired,
  setOrderArticleMetadataFailureReason: PropTypes.func.isRequired,
  isDeliveryFailed: PropTypes.bool.isRequired,
};

export default DeliveryArticle;
