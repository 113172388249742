import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import OfflineRecap from "components/OfflineRecap";
import HalfdaySwitch from "components/HalfdaySwitch";
import {
  UPDATE_ROADMAP_POSITIONS,
  makeUpdatePositionOptions,
} from "./updateRoadmapPositionsMutation";
import Header from "./Header";
import RoundStarter from "./RoundStarter";
import TaskList from "./TaskList";

const computePositions = (roadmap) => ({
  am: roadmap.am.reduce((acc, item) => {
    return {
      ...acc,
      [item.__typename === "RoadmapContractor"
        ? item.round_line_position
        : item.order.round_line_position]: item.id,
    };
  }, {}),
  pm: roadmap.pm.reduce((acc, item) => {
    return {
      ...acc,
      [item.__typename === "RoadmapContractor"
        ? item.round_line_position
        : item.order.round_line_position]: item.id,
    };
  }, {}),
});

const usePositions = (initialPositions) => {
  const [positions, setPositions] = useState(initialPositions);

  const updatePosition = ({ halfDay, item, direction }) => (e) => {
    const wantedPosition =
      Number(item.position) + (direction === "DOWN" ? 1 : -1);
    const replacedItem = positions[halfDay][wantedPosition];

    if (replacedItem) {
      const updatedHalfdayPositions = {
        ...positions[halfDay],
        [item.position]: replacedItem,
        [wantedPosition]: item.id,
      };
      setPositions({
        ...positions,
        [halfDay]: updatedHalfdayPositions,
      });
    }
    e.preventDefault();
  };

  const resetPositions = () => {
    setPositions(initialPositions);
  };

  return { positions, setPositions, updatePosition, resetPositions };
};

const Roadmap = ({ roadmap, reloadRoadmap, reloading }) => {
  const [isReordering, setIsReordering] = useState(false);
  const [selectedHalfday, setSelectedHalfday] = useState(null);
  const initialPositions = computePositions(roadmap);
  const {
    positions,
    setPositions,
    updatePosition,
    resetPositions,
  } = usePositions(initialPositions);

  const [updatePositions] = useMutation(UPDATE_ROADMAP_POSITIONS, {
    onCompleted: () => setIsReordering(false),
  });

  useEffect(() => {
    setPositions(computePositions(roadmap));
    setIsReordering(false);
  }, [roadmap, setPositions]);

  return (
    <div className="container">
      <Header
        roadmap={roadmap}
        isReordering={isReordering}
        setIsReordering={setIsReordering}
        reloadRoadmap={reloadRoadmap}
        reloading={reloading}
      />
      <OfflineRecap roadmap={roadmap} />
      {!roadmap.am.length || !roadmap.pm.length ? null : (
        <HalfdaySwitch
          selectedHalfday={selectedHalfday}
          setSelectedHalfday={setSelectedHalfday}
          canBeNotSelected={true}
        />
      )}
      {roadmap.am.length &&
      (!selectedHalfday || selectedHalfday === "am" || !roadmap.pm.length) ? (
        <section className="round-section">
          <h2 className="title">Matin</h2>
          <RoundStarter roadmap={roadmap} halfDay="am" />
          <ul className="list">
            <TaskList
              items={roadmap.am}
              halfDay="am"
              isReordering={isReordering}
              positions={positions}
              updatePosition={updatePosition}
              roadmap={roadmap}
            />
          </ul>
        </section>
      ) : null}
      {roadmap.pm.length &&
      (!selectedHalfday || selectedHalfday === "pm" || !roadmap.am.length) ? (
        <section className="round-section">
          <h2 className="title">Après-midi</h2>
          <RoundStarter roadmap={roadmap} halfDay="pm" />
          <ul className="list">
            <TaskList
              items={roadmap.pm}
              halfDay="pm"
              isReordering={isReordering}
              positions={positions}
              updatePosition={updatePosition}
              roadmap={roadmap}
            />
          </ul>
        </section>
      ) : null}
      {isReordering && (
        <footer className="round-footer">
          <button className="button" onClick={resetPositions}>
            Annuler
          </button>
          <button
            className="button"
            onClick={() => {
              updatePositions(makeUpdatePositionOptions(roadmap, positions));
            }}
          >
            Valider
          </button>
        </footer>
      )}
    </div>
  );
};

Roadmap.propTypes = {
  reloading: PropTypes.bool.isRequired,
  reloadRoadmap: PropTypes.func.isRequired,
  roadmap: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    am: PropTypes.array.isRequired,
    pm: PropTypes.array.isRequired,
    started: PropTypes.shape({
      am: PropTypes.bool.isRequired,
      pm: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

export default Roadmap;
