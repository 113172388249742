import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ApolloProvider } from "@apollo/client";
import makeApolloClient, { applyOfflineQueries } from "utils/makeApolloClient";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Loading from "components/Loading";
import Header from "components/Header";
import Round from "pages/Round";
import Delivery from "pages/Delivery";
import Settings from "pages/Settings";
import Picking from "pages/Picking";
import { AuthContext, useAuthState } from "utils/useAuth";
import { AppTitleContext } from "utils/useAppTitle";
import useNetworkStatus from "utils/useNetworkStatus";

import "./main.scss";

const App = ({ client }) => {
  const [user, setUser] = useAuthState();
  const [title, setTitle] = useState(null);

  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={{ user, setUser }}>
        <AppTitleContext.Provider value={{ title, setTitle }}>
          <BrowserRouter>
            <div className="app-container">
              <Header />
              <main className="mainview" role="main">
                <Switch>
                  <Route exact path="/">
                    <Round />
                  </Route>
                  <Route exact path="/settings">
                    <Settings />
                  </Route>
                  <Route path="/delivery/:deliveryId">
                    <Delivery />
                  </Route>
                  <Route path="/picking/:pickingId">
                    <Picking />
                  </Route>
                  <Route path="/">404</Route>
                </Switch>
              </main>
            </div>
          </BrowserRouter>
        </AppTitleContext.Provider>
      </AuthContext.Provider>
    </ApolloProvider>
  );
};

App.propTypes = {
  client: PropTypes.object.isRequired,
};

const AsyncAppLoader = () => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const isOnline = useNetworkStatus();

  // We use an async cache, so we have to handle an async Apollo client
  useEffect(() => {
    makeApolloClient().then(async (client) => {
      // This allows to apply optimistic UI changes when booting the app
      // in offline mode
      if (!isOnline) {
        applyOfflineQueries(client);
      }
      setClient(client);
      setLoading(false);
    });

    // When the service-worker finished its task
    // we have to initialize a new client to handle cache updates
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data.code === "restore") {
          makeApolloClient().then((client) => {
            setClient(client);
            setLoading(false);
          });
        }
      });
    }
  }, [isOnline]);

  if (loading) {
    return <Loading />;
  }

  return <App client={client} />;
};

export default AsyncAppLoader;
