import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ButtonWithIcon from "components/ButtonWithIcon";
import useClickOutsideListener from "utils/useClickOutsideListener";
import { pickingPhotosPath } from "utils/config";

const DeleteAlert = ({ isVisible, setIsVisible, handleDeletePhoto }) => {
  return (
    <div
      className={classnames("delete-alert", {
        "delete-alert--visible": isVisible,
      })}
    >
      <ButtonWithIcon onClick={() => setIsVisible(false)} icon="times">
        Annuler
      </ButtonWithIcon>
      <ButtonWithIcon onClick={handleDeletePhoto} icon="trash">
        Confirmer suppression
      </ButtonWithIcon>
    </div>
  );
};

DeleteAlert.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
};

const ModalCarousel = ({
  photos,
  children,
  handleDeletePhoto,
  disableDeletion,
}) => {
  const [visibleCarousel, setVisibleCarousel] = React.useState(false);
  const [currentPhoto, setCurrentPhoto] = React.useState(0);
  const [visibleDeleteAlert, setVisibleDeleteAlert] = React.useState(false);

  const carouselWrapperRef = React.useRef();
  useClickOutsideListener(carouselWrapperRef, () => handleClose());

  const modalClasses = classnames("modal-carousel", {
    "modal-carousel--visible": visibleCarousel,
  });

  const handleClose = () => {
    if (visibleDeleteAlert) {
      setVisibleDeleteAlert(false);
    }

    setVisibleCarousel(false);
  };

  const handleChangePhoto = (direction) => {
    if (visibleDeleteAlert) {
      return;
    }

    const isLastPhoto = currentPhoto === photos.length - 1;
    const isFirstPhoto = currentPhoto === 0;

    if (direction === "next") {
      setCurrentPhoto(isLastPhoto ? 0 : currentPhoto + 1);
    }

    if (direction === "prev") {
      setCurrentPhoto(isFirstPhoto ? photos.length - 1 : currentPhoto - 1);
    }
  };

  const updateCarouselStateAndhandleDeletePhoto = (
    photoIndex,
    photoBelongsToAnIncident
  ) => {
    const updatedPhotos = photos.filter((_, index) => index !== photoIndex);

    setVisibleDeleteAlert(false);
    if (updatedPhotos.length === 0) {
      handleClose();
    }

    if (photoIndex + 1 === photos.length) {
      setCurrentPhoto(photoIndex - 1);
    }

    handleDeletePhoto(updatedPhotos, photoBelongsToAnIncident);
  };

  const makePhotoSrc = (photo) => {
    const allowedExtensions = ["jpg", "png"];
    const extension = photo.base64.split('.').pop();
    return allowedExtensions.includes(extension) ? `${pickingPhotosPath}${photo.base64}` : photo.base64;
  }

  return (
    <>
      <div className={modalClasses}>
        <div ref={carouselWrapperRef} className="modal-carousel__wrapper">
          <div className="modal-carousel__photos">
            <i
              className="fas fa-chevron-left fa-3x"
              onClick={() => handleChangePhoto("prev")}
            ></i>
            {photos.map((photo, index) => (
              <div
                key={`photo-${index}`}
                className={classnames("modal-carousel__photo", {
                  "modal-carousel__photo--visible": index === currentPhoto,
                })}
              >
                <img alt="" src={makePhotoSrc(photo)} />
                <DeleteAlert
                  isVisible={visibleDeleteAlert}
                  setIsVisible={setVisibleDeleteAlert}
                  handleDeletePhoto={() =>
                    updateCarouselStateAndhandleDeletePhoto(
                      index,
                      photo.photoBelongsToAnIncident
                    )
                  }
                />
              </div>
            ))}
            <i
              className="fas fa-chevron-right fa-3x"
              onClick={() => handleChangePhoto("next")}
            ></i>
          </div>
          {!disableDeletion && (
            <div className="modal-carousel__delete">
              <ButtonWithIcon
                onClick={() => setVisibleDeleteAlert(true)}
                icon="trash"
              >
                Supprimer la photo
              </ButtonWithIcon>
            </div>
          )}
        </div>
      </div>
      <div className="modal-action" onClick={() => setVisibleCarousel(true)}>
        {children}
      </div>
    </>
  );
};

ModalCarousel.propTypes = {
  photos: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  disableDeletion: PropTypes.bool.isRequired,
};

export default ModalCarousel;
