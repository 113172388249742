import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const ButtonWithIcon = ({
  icon,
  iconPosition = "left",
  children,
  size,
  className,
  ...buttonProps
}) => {
  if (!icon) {
    console.warn("You must provide an icon prop to ButtonWithIcon component");
    return null;
  }

  const faIcon = <i className={`fa fa-${icon}`} />;
  const baseClassName = className
    ? `button-with-icon ${className}`
    : "button-with-icon";

  return (
    <button
      {...buttonProps}
      className={classNames(baseClassName, {
        [`button-with-icon--${size}`]: size,
      })}
    >
      {iconPosition === "left" && faIcon}
      {children}
      {iconPosition === "right" && faIcon}
    </button>
  );
};

ButtonWithIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["big"]),
  className: PropTypes.string,
};

export default ButtonWithIcon;
