import React from "react";
import PropTypes from "prop-types";

const QualitySheetFormSection = ({ title, warning, rows }) => {
  return (
    <div className="quality-sheet-form-section">
      <div className="quality-sheet-form-section__title">{title}</div>
      <div className="quality-sheet-form-section__warning">{warning}</div>
      <div className="quality-sheet-form-section__rows">{rows}</div>
    </div>
  );
};

QualitySheetFormSection.propTypes = {
  title: PropTypes.string.isRequired,
  warning: PropTypes.node,
  rows: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
};

export default QualitySheetFormSection;
