import React from "react";
import PropTypes from "prop-types";

const FailureReasonSelector = ({
  disabled,
  value,
  handleChangeFailureReason,
  metadataId = null,
}) => {
  return (
    <select
      disabled={disabled}
      className="failure-reason-selector"
      name="failure_reason"
      value={value}
      onChange={(e) => {
        handleChangeFailureReason(e.target.value, metadataId);
      }}
    >
      <option value="" />
      <option value="closed">
        Ne peut pas être livré dans la pièce de destination
      </option>
      <option value="damaged">Produit abimé</option>
      <option value="refused">Produit refusé par le client</option>
      <option value="customerCancellation">Produit annulé par le client</option>
      <option value="partialDelivery">Colis manquant</option>
      <option value="missingInfo">Information ou document manquant</option>
      <option value="notready">Produit non prêt (enlèvement)</option>
    </select>
  );
};

FailureReasonSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  metadataId: PropTypes.string,
  handleChangeFailureReason: PropTypes.func.isRequired,
};

export default FailureReasonSelector;
