import React from "react";
import PropTypes from "prop-types";
import Clipboard from "react-clipboard.js";

const Banner = ({ validations }) => {
  return (
    <section className="banner">
      {validations.errorMessage ? (
        <div className="banner--error">
          <div>Une erreur est survenue, contacter un administrateur.</div>
          <div className="banner__error-message">
            <span>Erreur : {validations.errorMessage}</span>
            <Clipboard data-clipboard-text={validations.errorMessage}>
              Copier l{"'"}erreur
            </Clipboard>
          </div>
        </div>
      ) : (
        <div className="banner--warning">Tournée non démarée</div>
      )}
    </section>
  );
};

Banner.propTypes = {
  validations: PropTypes.shape({
    errorMessage: PropTypes.string,
  }),
};

export default Banner;
