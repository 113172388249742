import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Redirect, useParams } from "react-router-dom";

import { ROADMAP_QUERY } from "pages/Round/roadmapQuery";
import Loading from "components/Loading";
import DeliverySubmit from "components/Delivery/Submit";
import CancelScreenModal from "components/Delivery/CancelScreenModal";
import DeliveryInformation from "components/Delivery/Information";
import QualitySheet from "components/Delivery/QualitySheet";
import DeliveryForm from "components/Delivery/DeliveryForm";
import ConfirmationModal from "components/Delivery/ConfirmationModal";
import TruckChangeModal from "components/Delivery/TruckChangeModal";
import DeliveryActions from "./DeliveryActions";
import Banner from "./Banner";
import Disclaimer from "components/Delivery/QualitySheet/Sections/Disclaimer";
import useAuth from "utils/useAuth";
import useDeliveryState from "./useDeliveryState";
import AskBeforeLeaving from "components/AskBeforeLeaving";

const DeliveryFailedReason = ({
  failureReason,
  customFailureReason,
  setFailureReason,
  setCustomFailureReason,
}) => {
  return (
    <div className="delivery-failed-reason">
      <div className="delivery-failed-reason__label">
        Raison de l&apos;échec de la livraison
      </div>
      <select
        name="failure_reason"
        value={failureReason}
        onChange={(ev) => {
          ev.preventDefault();
          setFailureReason(ev.target.value);
          if (customFailureReason) {
            setCustomFailureReason("");
          }
        }}
      >
        <option value="">Autre</option>
        <option value="absent">Client absent</option>
        <option value="customerCancellation">Annulation client</option>
        <option value="addressNotFound">Adresse non trouvée</option>
        <option value="wrongAddress">Adresse incorrecte</option>
        <option value="breakdown">Panne véhicule</option>
        <option value="unknown">Personne inconnue</option>
        <option value="strike">Impossible : grève</option>
        <option value="weather">Impossible : conditions météo</option>
      </select>
      {!failureReason && (
        <textarea
          name="custom_failure_reason"
          placeholder="Raison de l'échec de la livraison..."
          value={customFailureReason}
          onChange={(ev) => {
            ev.preventDefault();
            setCustomFailureReason(ev.target.value);
          }}
        />
      )}
    </div>
  );
};

DeliveryFailedReason.propTypes = {
  failureReason: PropTypes.string,
  customFailureReason: PropTypes.string,
  setFailureReason: PropTypes.func.isRequired,
  setCustomFailureReason: PropTypes.func.isRequired,
};

const Delivery = ({ roadmap, delivery }) => {
  const { display, validations, formState, actions } = useDeliveryState(
    roadmap,
    delivery
  );
  const someOrderArticlesAreNotDelivered = React.useMemo(() => {
    return Object.keys(formState.orderArticles).some((orderArticleKey) =>
      formState.orderArticles[orderArticleKey].metadata.some(
        (metadatum) => !metadatum.is_delivered
      )
    );
  }, [formState.orderArticles]);

  const someOrderArticlesHaveNoFailureReason = React.useMemo(() => {
    return Object.keys(formState.orderArticles).some((orderArticleKey) =>
      formState.orderArticles[orderArticleKey].metadata.some((metadatum) => {
        return !metadatum.is_delivered && !metadatum.failure_reason;
      })
    );
  }, [formState.orderArticles]);

  const cancelCallback = React.useCallback(() => {
    actions.setShouldAskBeforeLeavingDelivery(!validations.isValidated);
    actions.cancelValidateDelivery();
  }, [actions, validations]);

  if (display.showCancelScreen) {
    return <CancelScreenModal cancelCallback={cancelCallback} />;
  }

  if (display.showConfirm) {
    return (
      <ConfirmationModal
        delivery={delivery}
        payments={formState.payments}
        SAVComment={formState.SAVComment}
        validateDelivery={actions.validateDelivery}
        setShouldAskBeforeLeavingDelivery={
          actions.setShouldAskBeforeLeavingDelivery
        }
      />
    );
  }

  return (
    <>
      <AskBeforeLeaving
        shouldAsk={display.shouldAskBeforeLeavingDelivery}
        message={
          "Vous n'avez pas validé la fiche client. Êtes-vous sûr de vouloir quitter cette page ? Afin de conserver votre progression, veuillez cliquer sur le bouton 'VALIDER' en bas de la page"
        }
      />
      <div className="delivery-view">
        {(!validations.hasRoundStarted || validations.errorMessage) && (
          <Banner validations={validations} />
        )}
        {display.showQualitySheet ? (
          <div className="delivery-view__quality-sheet">
            <QualitySheet
              someOrderArticlesAreNotDelivered={
                someOrderArticlesAreNotDelivered
              }
              articles={delivery.order.articles}
              qualitySheet={formState.qualitySheet}
              hasSAV={!!formState.SAVComment}
              actions={{
                ...actions.qualitySheetActions,
                setShowQualitySheet: actions.setShowQualitySheet,
              }}
              errors={validations.qualitySheetErrors}
              submitButton={
                (validations.isValidated || display.showQualitySheet) &&
                validations.hasRoundStarted && (
                  <DeliverySubmit
                    isDisabled={!validations.hasValidPaymentPhotos}
                    isValidated={validations.isValidated}
                    isLoading={display.isLoading}
                    isOnline={display.isOnline}
                    hasError={validations.validateError}
                    handleSubmit={actions.handleSubmit}
                  />
                )
              }
            />
          </div>
        ) : (
          <div className="delivery-view__delivery-sheet">
            <DeliveryActions
              validations={validations}
              display={display}
              actions={actions}
              formState={formState}
            />

            {display.isDeliveryFailed && (
              <DeliveryFailedReason
                failureReason={formState.failureReason}
                customFailureReason={formState.customFailureReason}
                setFailureReason={actions.setFailureReason}
                setCustomFailureReason={actions.setCustomFailureReason}
              />
            )}
            <DeliveryInformation delivery={delivery} />
            <Disclaimer
              disabled={validations.isValidated}
              disclaimer={formState.qualitySheet.decharge_responsabilite}
              toggleDisclaimer={actions.qualitySheetActions.toggleDisclaimer}
              setDisclaimerUserInput={
                actions.qualitySheetActions.setDisclaimerUserInput
              }
            />
            <DeliveryForm
              delivery={delivery}
              formState={formState}
              actions={actions}
              validations={validations}
              display={display}
              someOrderArticlesHaveNoFailureReason={
                someOrderArticlesHaveNoFailureReason
              }
              someOrderArticlesAreNotDelivered={
                someOrderArticlesAreNotDelivered
              }
              handleSubmit={actions.handleSubmit}
            />
          </div>
        )}
      </div>
      <TruckChangeModal
        open={display.showTruckChange}
        closeModal={() => actions.setShowTruckChange(false)}
        roadmap={roadmap}
        order={delivery.order}
      />
    </>
  );
};

Delivery.propTypes = {
  roadmap: PropTypes.object.isRequired,
  delivery: PropTypes.object.isRequired,
};

const DeliveryLoader = ({ code }) => {
  const { deliveryId } = useParams();
  const { data } = useQuery(ROADMAP_QUERY, {
    variables: { code },
    fetchPolicy: "network-only",
  });

  if (data?.roadmap?.__typename !== "Roadmap") {
    return <Loading />;
  }

  const delivery = [...data.roadmap.am, ...data.roadmap.pm].find(
    ({ id }) => id === deliveryId
  );

  if (!delivery) {
    return <Redirect to="/" />;
  }

  return <Delivery roadmap={data.roadmap} delivery={delivery} />;
};

DeliveryLoader.propTypes = {
  code: PropTypes.string.isRequired,
};

const AuthCheck = () => {
  const { user } = useAuth();

  if (!user) {
    return <Redirect to="/" />;
  }

  return <DeliveryLoader code={user.code} />;
};

export default AuthCheck;
