import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Redirect, useParams } from "react-router-dom";
import BarcodeScanner from "components/BarcodeScanner";
import PhotoInput from "components/PhotoInput";
import AskBeforeLeaving from "components/AskBeforeLeaving";
import Loading from "components/Loading";
import PickingList from "./PickingList";
import { ROADMAP_QUERY } from "pages/Round/roadmapQuery";
import useAuth from "utils/useAuth";
import { formatOrderForPicking } from "utils/picking";
import usePicking, { PICKUP_INCIDENTS } from "./usePicking";
import PickingHeader from "./PickingHeader";
import OrderPicking from "./OrderPicking";
import { useLocation } from "react-router-dom";

const ProductCanBeDeliveredModal = ({ open, handleProductCanBeDelivered }) => {
  if (!open) {
    return null;
  }

  return (
    <div className="product-can-be-delivered-modal">
      <div className="product-can-be-delivered-modal__container">
        <div className="product-can-be-delivered-modal__text">
          Le produit peut-il quand même être livré ?
        </div>
        <div className="product-can-be-delivered-modal__actions">
          <button onClick={() => handleProductCanBeDelivered(false)}>
            Non
          </button>
          <button onClick={() => handleProductCanBeDelivered(true)}>Oui</button>
        </div>
      </div>
    </div>
  );
};

ProductCanBeDeliveredModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleProductCanBeDelivered: PropTypes.func.isRequired,
};

const Picking = ({
  data,
  contractor,
  setAdditionalContractorsDate,
  additionalContractorsDate,
  truckLineName,
}) => {
  const location = useLocation();

  const [
    currentArticleMetadataPath,
    setCurrentArticleMetadataPath,
  ] = React.useState(null);

  const [photoInputState, setPhotoInputState] = React.useState({
    open: false,
    isAnIncident: false,
    callback: null,
  });

  const [targetEAN, setTargetEan] = React.useState(null);
  const [barcodeScannerIsopen, setBarcodeScannerIsOpen] = React.useState(false);

  const [
    productCanBeDeliveredModalOpen,
    setProductCanBeDeliveredModalOpen,
  ] = React.useState(false);

  const {
    currentPickingInfos,
    metadataToSynchronize,
    ordersCommentsToSynchronize,
    addArticlePickupPhoto,
    deleteArticlePickupPhoto,
    handleSuccessfulPickup,
    resetPickupStatus,
    updatePickupIncidentReason,
    updateOrderComment,
    synchronizePicking,
    synchronizePickingLoading,
    synchronizePickingError,
  } = usePicking(data);

  /* This is only used when we specify a state to the picking page, specifying a state means we only want to get data from a specific command */
  const orderPicking = React.useMemo(() => {
    if (!location.state) {
      return null;
    }

    const { orderDate, orderHalfDay, orderId } = location.state;

    const orderPickingForDayHalfDayAndOrderId = currentPickingInfos
      .find((pickingInfo) => {
        return pickingInfo.date === orderDate && pickingInfo;
      })
      ?.[orderHalfDay].find((order) => order.id === orderId);

    return orderPickingForDayHalfDayAndOrderId;
  }, [currentPickingInfos, location.state]);

  const setPhotoInputOpen = (open) => {
    setPhotoInputState((previousPhotoInputState) => ({
      ...previousPhotoInputState,
      open,
    }));
  };

  const setPhotoBelongsToAnIncident = (belongsToAnIncident) => {
    setPhotoInputState((previousPhotoInputState) => ({
      ...previousPhotoInputState,
      belongsToAnIncident,
    }));
  };

  const setPhotoCallback = (callback) => {
    setPhotoInputState((previousPhotoInputState) => ({
      ...previousPhotoInputState,
      callback,
    }));
  };

  /** This functions could be move into the usePicking hook but for the moment we will keep them here */

  const handleTakePhoto = (metadataPath) => {
    setPhotoInputOpen(true);
    setCurrentArticleMetadataPath(metadataPath);
  };

  const handleDamagedPackagingOrProductPhoto = (metadataPath) => {
    handleTakePhoto(metadataPath);
    /* 
      big hack to provide us a way to "discriminate" photos to know if they belong to an incident.
      If a photo belong to an incident we don't count them in the total number of photos 
      either way users would not be able to add a photo when declaring an incident if there is no photo left.
    */
    setPhotoBelongsToAnIncident(true);
    setPhotoCallback(() => setProductCanBeDeliveredModalOpen(true));
  };

  const handleDeletePhoto = (
    metadataPath,
    updatedPhotos,
    photoBelongsToAnIncident
  ) => {
    deleteArticlePickupPhoto(
      metadataPath,
      updatedPhotos,
      photoBelongsToAnIncident
    );
  };

  const onTakePhotoSuccessful = (base64) => {
    addArticlePickupPhoto(currentArticleMetadataPath, {
      base64,
      belongsToAnIncident: photoInputState.belongsToAnIncident,
    });

    /* Reset the tracker */
    if (photoInputState.belongsToAnIncident) {
      setPhotoBelongsToAnIncident(false);
    }

    if (typeof photoInputState.callback === "function") {
      photoInputState.callback();
    }
  };

  const handleScan = (metadataPath, ean) => {
    setCurrentArticleMetadataPath(metadataPath);
    setTargetEan(ean);
    setBarcodeScannerIsOpen(true);
  };

  const onSuccessfulScan = () => {
    setTargetEan(null);
    setBarcodeScannerIsOpen(false);
    handleSuccessfulPickup(currentArticleMetadataPath);
  };

  const onAbortScan = (success, reason) => {
    setTargetEan(null);
    setBarcodeScannerIsOpen(false);

    return updatePickupIncidentReason(
      currentArticleMetadataPath,
      reason,
      success
    );
  };

  const handleProductCanBeDelivered = (canBeDelivered) => {
    updatePickupIncidentReason(
      currentArticleMetadataPath,
      PICKUP_INCIDENTS.DAMAGED_PACKAGING_OR_PRODUCT.value,
      canBeDelivered
    );
    setProductCanBeDeliveredModalOpen(false);
    setPhotoCallback(null);
  };

  const usedDates = additionalContractorsDate
    .filter(
      (contractorDate) =>
        contractorDate.contractor_id === contractor.id.split("_-_")[0]
    )
    .map((contractorDate) => {
      return new Date(contractorDate.date);
    });

  return (
    <>
      <AskBeforeLeaving
        shouldAsk={
          metadataToSynchronize.length > 0 ||
          ordersCommentsToSynchronize.length > 0
        }
        message={
          "Vous avez des modifications non-synchronisées. Êtes-vous sûr de vouloir quitter cette page ? Afin de synchroniser vos changements veuillez cliquer sur le bouton 'SYNCHRONISER' en haut à droite de la page."
        }
      />

      {synchronizePickingError && (
        <p className="alert alert--error">
          Une erreur est survenue lors de la synchronisation des données,
          veuillez contacter un administrateur.
        </p>
      )}

      <div className="picking">
        <PickingHeader
          metadataToSynchronize={metadataToSynchronize}
          ordersCommentsToSynchronize={ordersCommentsToSynchronize}
          synchronizePickingLoading={synchronizePickingLoading}
          synchronizePicking={() => synchronizePicking(truckLineName)}
          contractor={contractor}
        />

        {orderPicking ? (
          <OrderPicking
            order={orderPicking}
            handleScan={handleScan}
            handleTakePhoto={handleTakePhoto}
            handleDeletePhoto={handleDeletePhoto}
            resetPickupStatus={resetPickupStatus}
            handleSuccessfulPickup={handleSuccessfulPickup}
            updatePickupIncidentReason={updatePickupIncidentReason}
            handleDamagedPackagingOrProductPhoto={
              handleDamagedPackagingOrProductPhoto
            }
            updateOrderComment={updateOrderComment}
          />
        ) : (
          <PickingList
            setAdditionalContractorsDate={setAdditionalContractorsDate}
            currentPickingInfos={currentPickingInfos}
            handleScan={handleScan}
            handleTakePhoto={handleTakePhoto}
            handleDeletePhoto={handleDeletePhoto}
            resetPickupStatus={resetPickupStatus}
            handleSuccessfulPickup={handleSuccessfulPickup}
            updatePickupIncidentReason={updatePickupIncidentReason}
            handleDamagedPackagingOrProductPhoto={
              handleDamagedPackagingOrProductPhoto
            }
            updateOrderComment={updateOrderComment}
            additionalContractorsDate={additionalContractorsDate}
            contractor={contractor}
            usedDates={usedDates}
          />
        )}

        <BarcodeScanner
          open={barcodeScannerIsopen}
          setOpen={setBarcodeScannerIsOpen}
          target={targetEAN}
          onSuccessfulScan={onSuccessfulScan}
          /* Any action beside scanning successfully */
          onAbortScan={onAbortScan}
        />
        <PhotoInput
          open={photoInputState.open}
          setOpen={setPhotoInputOpen}
          onTakePhotoSuccessful={onTakePhotoSuccessful}
        />
        <ProductCanBeDeliveredModal
          open={productCanBeDeliveredModalOpen}
          handleProductCanBeDelivered={handleProductCanBeDelivered}
        />
      </div>
    </>
  );
};

Picking.propTypes = {
  data: PropTypes.array.isRequired,
  contractor: PropTypes.object.isRequired,
  truckLineName: PropTypes.string.isRequired,
  additionalContractorsDate: PropTypes.array.isRequired,
  setAdditionalContractorsDate: PropTypes.func.isRequired,
};

const isLocalStorageAvailable = () => {
  return window && window.localStorage;
};

const guardAgainstUnavailableLocalStorage = (callback) => {
  if (!isLocalStorageAvailable()) {
    throw new Error("The localStorage is not available");
  }

  return callback;
};

const getPersistentAdditionalContractorsDate = guardAgainstUnavailableLocalStorage(
  () => {
    return JSON.parse(window.localStorage.getItem("ADDITIONAL-DATES") || null);
  }
);

const setPersistentAdditionalContractorsDate = guardAgainstUnavailableLocalStorage(
  (additionalContractorsDate) => {
    return window.localStorage.setItem(
      "ADDITIONAL-DATES",
      JSON.stringify(additionalContractorsDate)
    );
  }
);

const PickingLoader = ({ code }) => {
  const { pickingId } = useParams();
  const [
    additionalContractorsDate,
    setAdditionalContractorsDate,
  ] = React.useState(getPersistentAdditionalContractorsDate() || []);

  const { data, refetch } = useQuery(ROADMAP_QUERY, {
    variables: { code, additionalContractorsDate },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    setPersistentAdditionalContractorsDate(additionalContractorsDate);
    refetch();
  }, [additionalContractorsDate, refetch]);

  if (data?.roadmap?.__typename !== "Roadmap") {
    return <Loading />;
  }

  const picking = [...data.roadmap.am, ...data.roadmap.pm].find(
    ({ id }) => id === pickingId
  );

  if (!picking) {
    return <Redirect to="/" />;
  }

  const contractor = {
    id: picking.id,
    name: picking.name,
    address: {
      address: picking.address,
      postal_code: picking.postal_code,
    },
  };

  const dataPicking = picking.orders.reduce((acc, order) => {
    const dateAlreadyDefined = acc.find((el) => el.date === order.date);

    const orderFormatted = formatOrderForPicking(order);

    if (!dateAlreadyDefined) {
      return [
        ...acc,
        {
          date: order.date,
          am: order.halfday === "am" ? [orderFormatted] : [],
          pm: order.halfday === "pm" ? [orderFormatted] : [],
        },
      ];
    }

    return acc.map((el) => {
      if (el.date === order.date) {
        return {
          date: el.date,
          am: order.halfday === "am" ? [...el.am, orderFormatted] : el.am,
          pm: order.halfday === "pm" ? [...el.pm, orderFormatted] : el.pm,
        };
      }
      return el;
    });
  }, []);

  const dataPickingOrderByDate = dataPicking.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  const truckLineName = data?.roadmap?.truck_line_name || "";

  return (
    <Picking
      contractor={contractor}
      data={dataPickingOrderByDate}
      setAdditionalContractorsDate={setAdditionalContractorsDate}
      additionalContractorsDate={additionalContractorsDate}
      truckLineName={truckLineName}
    />
  );
};

PickingLoader.propTypes = {
  code: PropTypes.string.isRequired,
};

const AuthCheck = () => {
  const { user } = useAuth();

  if (!user) {
    return <Redirect to="/" />;
  }

  return <PickingLoader code={user.code} />;
};

export default AuthCheck;
