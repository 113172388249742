import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import OfflineQueryStore from "utils/offlineQueryStore";
import useNetworkStatus from "utils/useNetworkStatus";

const operationNameToLabel = {
  startRound: "Démarrer la tournée",
  updateRoadmapPositions: "Réordination des livraisons",
  changeTruck: "Attribution à un autre camion",
};

const listOfflineQueryWaiting = async () => {
  const offlineQueries = await OfflineQueryStore.getAllSorted();

  const operations = offlineQueries.filter(Boolean).reduce((acc, query) => {
    acc[query.operationName] = {
      name: operationNameToLabel[query.operationName] || query.operationName,
    };
    return acc;
  }, {});

  return operations;
};

const OfflineSyncRecap = ({ roadmap }) => {
  const isOnline = useNetworkStatus();
  const [offlineOperations, setOfflineOperations] = useState({});

  useEffect(() => listOfflineQueryWaiting().then(setOfflineOperations), [
    setOfflineOperations,
    roadmap,
    isOnline,
  ]);

  if (isOnline) {
    return null;
  }

  return (
    <div className="offline-recap">
      <div className="offline-recap__title">Vous êtes hors-ligne</div>
      {Object.keys(offlineOperations).length > 0 && (
        <div className="offline-recap__body">
          Les actions suivantes seront synchronisées lorsque vous aurez accès à
          du réseau&nbsp;:&nbsp;
          <ul>
            {Object.values(offlineOperations).map((operation) => {
              return <li key={operation.name}>{operation.name}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

OfflineSyncRecap.propTypes = {
  roadmap: PropTypes.object.isRequired,
};

export default OfflineSyncRecap;
