import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import useClickOutsideListener from "utils/useClickOutsideListener";

const WriteCommentModal = ({ comment, isVisible, setIsVisible, callback }) => {
  const [inputValue, setInputValue] = React.useState(comment || "");

  const wrapperRef = React.useRef();
  useClickOutsideListener(wrapperRef, () => setIsVisible(false));

  const modalClasses = classnames("write-comment-modal", {
    "write-comment-modal--visible": isVisible,
  });

  return (
    <div className={modalClasses}>
      <div ref={wrapperRef} className="write-comment-modal__wrapper">
        <div className="write-comment-modal__title">Rédiger un commentaire</div>
        <textarea
          className="write-comment-modal__input"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
        />
        <div className="write-comment-modal__buttons">
          <button
            onClick={() => {
              setIsVisible(false);
              setInputValue(comment || "");
            }}
          >
            Annuler
          </button>
          <button
            onClick={() => {
              callback(inputValue);
              setIsVisible(false);
            }}
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

WriteCommentModal.propTypes = {
  comment: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default WriteCommentModal;
