import React from "react";

const Loading = () => (
  <div className="_text-center _vertical-spacing">
    <i className="fa fa-sync fa-spin fa-lg fa-fw" />
    &nbsp;Chargement en cours...
  </div>
);

export default Loading;
