const CATEGORY_OF_ARTICLES_THAT_ARE_NOT_DISPLAYED = ["SERVICES"];

export const formatOrderForPicking = (order) => ({
  ...order,
  id: order.id,
  ref: order.reference,
  picking_status: order.picking_status,
  picking_comment: order.picking_comment,
  order_articles: order.articles
    .filter(({ article }) => {
      return !CATEGORY_OF_ARTICLES_THAT_ARE_NOT_DISPLAYED.includes(
        article?.category?.name
      );
    })
    .map((article) => {
      return {
        id: article.id,
        name: article.article.name,
        ean: article.ean,
        qty: article.quantity,
        numberOfPackages: article.number_of_packages,
        weight: article.weight,
        hasHeavyPackage: article.has_heavy_package,
        metadata: article.metadata,
      };
    }),
});
