const getAttributeValueByPath = (object, stringPath) => {
  const path = stringPath.split(/[.[\]'"]/).filter((p) => p);

  return path.reduce((acc, cur) => (acc ? acc[cur] : null), object);
};

const setAttributeValueByPath = (
  object,
  stringPath,
  value,
  append = false,
  mutate = false
) => {
  const path = stringPath.split(/[.[\]'"]/).filter((p) => p);

  const getExpectedValue = (current, value) => {
    if (append && Array.isArray(current)) {
      return [...current, value];
    }

    if (mutate && typeof current === "object" && typeof value === "object") {
      return { ...current, ...value };
    }

    return value;
  };

  path.reduce((acc, cur, index) => {
    return (acc[cur] =
      path.length === index + 1 ? getExpectedValue(acc[cur], value) : acc[cur]);
  }, object);
};

export { getAttributeValueByPath, setAttributeValueByPath };
