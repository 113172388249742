import { gql } from "@apollo/client";

export const DELIVERY_FRAGMENT = gql`
  fragment DeliveryFragment on RoadmapOrder {
    id
    customerIsAbsent
    delivered
    arrivalTime
    isDelayed
    isSmsSent
    comment
    SAVComment
    someArticleHaveNotBeenDelivered
    payments {
      id
      type
      payment_method
      paid_amount
    }
    SAVPhotos {
      id
      src
    }
    paymentPhotos {
      id
      src
    }
    order {
      id
      reference
      store_return
      round_line_position
      last_event
      picking_status
      prices {
        client_price
        store_settle
        financing_file
      }
      contractor {
        id
        identity {
          code
        }
      }
      client {
        id
        identity {
          firstname
          lastname
          phone
          phone_secondary
          email
        }
        address {
          address
          address_addition
          floor
          postal_code {
            postal_code
            city
          }
        }
      }
      observations {
        client
        internal
      }
      delivery {
        date
        from_time
        to_time
      }
      articles {
        id
        direct_drop_article
        drop_article
        install_article
        quantity
        has_heavy_package
        weight
        number_of_packages
        article {
          id
          name
          category {
            id
            name
          }
        }
        metadata {
          metadata_id
          order_article_id
          is_delivered
          failure_reason
          pickup_at
          pickup_by
          pickup_incident_reason
          pickup_photos
          pickup_status
        }
      }
    }
  }
`;

export const CONTRACTOR_FRAGMENT = gql`
  fragment ContractorFragment on RoadmapContractor {
    id
    round_line_position
    is_auto_generated
    code
    name
    address
    postal_code {
      postal_code
      city
    }
    orders {
      id
      reference
      date
      halfday
      picking_status
      picking_comment
      client {
        id
        identity {
          firstname
          lastname
          phone
          phone_secondary
          email
        }
        address {
          address
          address_addition
          floor
          postal_code {
            postal_code
            city
          }
        }
      }
      articles {
        id
        drop_article
        install_article
        quantity
        ean
        number_of_packages
        weight
        has_heavy_package
        article {
          id
          name
          category {
            id
            name
          }
        }
        metadata {
          metadata_id
          order_article_id
          pickup_at
          pickup_by
          pickup_incident_reason
          pickup_photos
          pickup_status
        }
      }
    }
  }
`;

export const ROADMAP_FRAGMENT = gql`
  ${DELIVERY_FRAGMENT}
  ${CONTRACTOR_FRAGMENT}

  fragment RoadmapFragment on Roadmap {
    id
    date
    code
    truck_name
    truck_line_name
    crewmates
    office {
      id
      name
    }
    started {
      am
      pm
    }
    am {
      ... on RoadmapOrder {
        ...DeliveryFragment
      }
      ... on RoadmapContractor {
        ...ContractorFragment
      }
    }
    pm {
      ... on RoadmapOrder {
        ...DeliveryFragment
      }
      ... on RoadmapContractor {
        ...ContractorFragment
      }
    }
  }
`;

export const ROADMAP_QUERY = gql`
  ${ROADMAP_FRAGMENT}
  query RoadmapQuery(
    $code: String!
    $additionalContractorsDate: [AdditionalContractorsDateInput]
  ) {
    roadmap(
      code: $code
      additionalContractorsDate: $additionalContractorsDate
    ) {
      __typename
      ... on Roadmap {
        ...RoadmapFragment
      }
    }
  }
`;
