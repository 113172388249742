import React from "react";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";

const Confirmation = ({ confirmation, toggleConfirmation }) => {
  return (
    <div className="quality-sheet__confirmation">
      <Checkbox
        label="Par sa signature, le client reconnaît avoir reçu, contrôlé la
        marchandise livrée et/ou installée ce jour et/ou la conformité des
        données indiquées ci-dessus."
        checked={confirmation}
        onChange={toggleConfirmation}
      />
    </div>
  );
};

Confirmation.propTypes = {
  confirmation: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
};

export default Confirmation;
