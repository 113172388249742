import { gql } from "@apollo/client";

export const SYNCHRONIZE_PICKING = gql`
  mutation synchronizePicking($input: SynchronizePickingInput!) {
    synchronizePicking(input: $input) {
      success
      message
    }
  }
`;
