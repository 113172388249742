import React from "react";
import PropTypes from "prop-types";
import ButtonWithIcon from "components/ButtonWithIcon";

const formatContractorAddress = ({ address, postal_code }) => {
  return `${address}, ${postal_code.postal_code} ${postal_code.city}`;
};

const PickingHeader = ({
  metadataToSynchronize,
  ordersCommentsToSynchronize,
  synchronizePickingLoading,
  synchronizePicking,
  contractor,
}) => (
  <div className="picking__header">
    <div className="picking__sync">
      <ButtonWithIcon
        disabled={
          (metadataToSynchronize.length === 0 &&
            ordersCommentsToSynchronize.length === 0) ||
          synchronizePickingLoading
        }
        icon="sync-alt"
        onClick={synchronizePicking}
      >
        Synchroniser
      </ButtonWithIcon>
    </div>
    <div>Magasin: {contractor.name}</div>
    <div>Adresse: {formatContractorAddress(contractor.address)}</div>
  </div>
);

PickingHeader.propTypes = {
  contractor: PropTypes.shape({
    address: PropTypes.shape({
      address: PropTypes.string.isRequired,
      postal_code: PropTypes.shape({
        postal_code: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
      }),
    }).isRequired,
    name: PropTypes.string.isRequired,
  }),
  metadataToSynchronize: PropTypes.array.isRequired,
  ordersCommentsToSynchronize: PropTypes.array.isRequired,
  synchronizePicking: PropTypes.func.isRequired,
  synchronizePickingLoading: PropTypes.bool.isRequired,
};

export default PickingHeader;
