import { Store, del, set, keys, clear as natifClear, get } from "idb-keyval";

const offlineStore = new Store("offline-queries", "offline-store");

const add = async (key, query) => {
  await set(key, query, offlineStore);
};

const remove = async (key) => {
  return await del(key, offlineStore);
};

const getAllSorted = async () => {
  let allKeys = await keys(offlineStore);
  allKeys.sort();

  return await Promise.all(allKeys.map((key) => get(key, offlineStore)));
};

const getKeys = async () => {
  return await keys(offlineStore);
};

const clear = async () => {
  return await natifClear(offlineStore);
};

const QueryStore = { add, remove, clear, getAllSorted, getKeys };

export default QueryStore;
