import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import { START_ROUND, makeStartRoundOptions } from "./startRoundMutation";

const RoundStarter = ({ roadmap, halfDay }) => {
  const [roundStarting] = useState(false);
  const [startRound] = useMutation(START_ROUND);

  return (
    <div className="actions">
      {roadmap.started[halfDay] ? null : roundStarting ? (
        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      ) : (
        <button
          type="button"
          className="button -positive"
          onClick={() => startRound(makeStartRoundOptions(roadmap, halfDay))}
        >
          Démarrer la tournée
        </button>
      )}
    </div>
  );
};

RoundStarter.propTypes = {
  roadmap: PropTypes.shape({
    started: PropTypes.shape({
      am: PropTypes.bool.isRequired,
      pm: PropTypes.bool.isRequired,
    }).isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  halfDay: PropTypes.oneOf(["am", "pm"]).isRequired,
};

export default RoundStarter;
