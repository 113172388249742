import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useHistory, Redirect } from "react-router-dom";

import { CHANGE_TRUCK, makeChangeTruckOptions } from "./changeTruckMutation";

const TruckChangeModal = ({ open, closeModal, order, roadmap }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [truckCode, setTruckCode] = useState("");
  const [changeTruck, { error, loading }] = useMutation(CHANGE_TRUCK, {
    onCompleted: ({ changeTruck }) => {
      if (changeTruck?.success) {
        history.push("/");
      } else {
        setErrorMessage(changeTruck.message);
      }
    },
  });

  if (error) {
    return <Redirect to="/" />;
  }

  if (!open) {
    return null;
  }

  return (
    <div className="modal">
      <header className="header">
        <div className="row">
          <div className="column">Attribuer à un autre camion</div>
        </div>
      </header>

      <div className="contain">
        <div className="row">
          <div className="column">
            <fieldset>
              <label htmlFor="truck">Identifiant du camion</label>
              <input
                type="number"
                placeholder="Identifiant du nouveau camion"
                id="truck"
                value={truckCode}
                onChange={(event) => setTruckCode(event.target.value)}
              />
            </fieldset>
          </div>
        </div>
      </div>

      {errorMessage === "UNKNOWN_CODE" && (
        <p className="_text-error">Identifiant de camion introuvable</p>
      )}

      {errorMessage && errorMessage !== "UNKNOWN_CODE" && (
        <p className="_text-error">
          Impossible de sauvegarder le changement du camion
        </p>
      )}

      <footer className="footer-section">
        <div className="row">
          <div className="column">
            <button className="button" onClick={closeModal}>
              Annuler
            </button>
            <button
              className="button"
              onClick={() => {
                changeTruck(
                  makeChangeTruckOptions(truckCode, order.id, roadmap)
                );
                setErrorMessage(null);
              }}
            >
              {loading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true" />
              ) : (
                "Valider"
              )}
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

TruckChangeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  roadmap: PropTypes.object.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default TruckChangeModal;
