import React from "react";
import PropTypes from "prop-types";
import PhotosInput from "components/PhotosInput";

const SAV = ({
  disabled,
  SAVPhotos,
  setSAVPhotos,
  SAVComment,
  setSAVComment,
  setIsCompliant,
}) => {
  return (
    <section className="sav-section">
      <div className="sav-section-comment">
        <div className="sav-section-comment__label">SAV - COMMENTAIRE : </div>
        <textarea
          disabled={disabled}
          className="sav-section-comment__textarea"
          placeholder="La livraison sera considérée comme non-conforme si ce champ est rempli."
          rows={3}
          value={SAVComment}
          onChange={(event) => {
            setIsCompliant(false);
            return setSAVComment(event.target.value);
          }}
        />
      </div>
      <PhotosInput
        disabled={disabled}
        photoList={SAVPhotos}
        setPhotoList={setSAVPhotos}
        label={<div className="photo-input__label">PHOTOS : </div>}
      />
    </section>
  );
};

SAV.propTypes = {
  disabled: PropTypes.bool.isRequired,
  SAVPhotos: PropTypes.array.isRequired,
  setSAVPhotos: PropTypes.func.isRequired,
  SAVComment: PropTypes.string.isRequired,
  setSAVComment: PropTypes.func.isRequired,
  setIsCompliant: PropTypes.func.isRequired,
  isDeliveryFailed: PropTypes.bool.isRequired,
};

export default SAV;
