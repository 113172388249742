import React from "react";
import PropTypes from "prop-types";
import ButtonWithIcon from "components/ButtonWithIcon";
import useClickOutsideListener from "utils/useClickOutsideListener";
import Tooltip from "components/Tooltip";
import ModalCarousel from "components/ModalCarousel";
import { PICKUP_INCIDENTS, PICKUP_STATUS } from "../usePicking";

const IncidentButton = ({
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
}) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const wrapperRef = React.useRef();
  useClickOutsideListener(wrapperRef, () => setMenuIsOpen(false));

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div ref={wrapperRef} className="incident-button">
      <ButtonWithIcon
        onClick={toggleMenu}
        icon="chevron-down"
        iconPosition="right"
      >
        Déclarer un incident
      </ButtonWithIcon>
      {menuIsOpen && (
        <div className="incident-button__menu">
          <div
            className="incident-button__menu-item"
            onClick={handleDamagedPackagingOrProductPhoto}
          >
            Emballage/Produit abîmé
          </div>
          <div
            className="incident-button__menu-item"
            onClick={() => {
              updatePickupIncidentReason(
                PICKUP_INCIDENTS.MISSING_PRODUCT.value,
                false
              );
              setMenuIsOpen(false);
            }}
          >
            Produit Manquant
          </div>
          <div
            className="incident-button__menu-item"
            onClick={() => {
              updatePickupIncidentReason(
                PICKUP_INCIDENTS.PRODUCT_OR_EAN_DOES_NOT_MATCH.value,
                false
              );
              setMenuIsOpen(false);
            }}
          >
            Modèle/EAN ne correspond pas
          </div>
        </div>
      )}
    </div>
  );
};

IncidentButton.propTypes = {
  updatePickupIncidentReason: PropTypes.func.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
};

const Miscellaneous = ({ metadata, handleDeletePhoto }) => {
  const photos = metadata?.pickup_photos;
  const hasPhotos = photos.length > 0;

  const pickupStatusIcon = React.useMemo(() => {
    if (metadata.pickup_status === PICKUP_STATUS.PICKUP_OK.value) {
      return (
        <Tooltip text={PICKUP_STATUS[metadata.pickup_status].label}>
          <i className="fas fa-check fa-lg" />
        </Tooltip>
      );
    }

    if (metadata.pickup_status === PICKUP_STATUS.PICKUP_FAILED.value) {
      return (
        <Tooltip text={PICKUP_STATUS[metadata.pickup_status].label}>
          <i className="fas fa-times fa-lg" />
        </Tooltip>
      );
    }

    return null;
  }, [metadata.pickup_status]);

  return (
    <div className="picking-article__miscellaneous">
      {pickupStatusIcon}

      {metadata.pickup_incident_reason && (
        <Tooltip text={PICKUP_INCIDENTS[metadata.pickup_incident_reason].label}>
          <i className="fas fa-exclamation-circle fa-lg" />
        </Tooltip>
      )}

      {hasPhotos && (
        <ModalCarousel
          photos={photos}
          handleDeletePhoto={handleDeletePhoto}
          disableDeletion={metadata.alreadySynchronized}
        >
          <i className="fas fa-camera fa-lg" />
        </ModalCarousel>
      )}
    </div>
  );
};

Miscellaneous.propTypes = {
  metadata: PropTypes.object.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
};

const PickupActionButton = ({
  article,
  metadata,
  handleScan,
  handleSuccessfulPickup,
  resetPickupStatus,
}) => {
  if (metadata.pickup_status === PICKUP_STATUS.PICKUP_OK.value) {
    return (
      <ButtonWithIcon
        disabled={metadata.pickup_incident_reason}
        className="cancel-pickup-button"
        onClick={() => resetPickupStatus(false)}
        icon="times"
      >
        Annuler enlévement
      </ButtonWithIcon>
    );
  }

  if (article.ean) {
    return (
      <ButtonWithIcon
        disabled={metadata.pickup_incident_reason}
        onClick={handleScan}
        icon="barcode"
      >
        Scan EAN
      </ButtonWithIcon>
    );
  }

  return (
    <ButtonWithIcon
      disabled={metadata.pickup_incident_reason}
      onClick={handleSuccessfulPickup}
      icon="hand-paper"
    >
      Enlever
    </ButtonWithIcon>
  );
};

PickupActionButton.propTypes = {
  article: PropTypes.shape({
    ean: PropTypes.string,
  }).isRequired,
  metadata: PropTypes.shape({
    pickup_status: PropTypes.string,
    pickup_incident_reason: PropTypes.string,
  }).isRequired,
  handleScan: PropTypes.func.isRequired,
  handleSuccessfulPickup: PropTypes.func.isRequired,
  resetPickupStatus: PropTypes.func.isRequired,
};

const PickingArticle = ({
  article,
  metadata,
  handleScan,
  handleTakePhoto,
  handleDeletePhoto,
  resetPickupStatus,
  handleSuccessfulPickup,
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
  canTakePhoto,
}) => {
  return (
    <div className="picking-article">
      <div className="picking-article__article">{article.name}</div>
      <div className="picking-article__ean">
        {article?.ean && article.ean !== "" ? article.ean : "N/A"}
      </div>
      <Miscellaneous
        metadata={metadata}
        handleDeletePhoto={handleDeletePhoto}
      />
      <div className="picking-article__number">
        {article?.numberOfPackages ? article.numberOfPackages : "?"}
      </div>
      <div
        className="picking-article__weight"
        style={{ color: article?.weight > 120 ? "red" : "inherit" }}
      >
        {article?.weight ? article?.weight : "?"}
      </div>
      <div className="picking-article__actions">
        {metadata.alreadySynchronized && metadata.pickup_status ? null : (
          <>
            <PickupActionButton
              article={article}
              metadata={metadata}
              handleScan={handleScan}
              handleSuccessfulPickup={handleSuccessfulPickup}
              resetPickupStatus={resetPickupStatus}
            />

            {metadata.pickup_incident_reason ? (
              <button
                className="reset-pickup-incident-button"
                onClick={() => resetPickupStatus(true)}
              >
                Annuler incident
              </button>
            ) : (
              <IncidentButton
                updatePickupIncidentReason={updatePickupIncidentReason}
                handleDamagedPackagingOrProductPhoto={
                  handleDamagedPackagingOrProductPhoto
                }
              />
            )}
            <ButtonWithIcon
              disabled={!canTakePhoto}
              icon="camera"
              onClick={handleTakePhoto}
            >
              Prendre une photo
            </ButtonWithIcon>
          </>
        )}
      </div>
    </div>
  );
};

PickingArticle.propTypes = {
  article: PropTypes.shape({
    name: PropTypes.string.isRequired,
    ean: PropTypes.string,
    numberOfPackages: PropTypes.number,
    weight: PropTypes.number,
    hasHeavyPackage: PropTypes.bool,
  }),
  metadata: PropTypes.object.isRequired,
  handleScan: PropTypes.func.isRequired,
  handleTakePhoto: PropTypes.func.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  resetPickupStatus: PropTypes.func.isRequired,
  handleSuccessfulPickup: PropTypes.func.isRequired,
  updatePickupIncidentReason: PropTypes.func.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
  canTakePhoto: PropTypes.bool.isRequired,
};

export default PickingArticle;
