import React from "react";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";

const NonInstallation = ({
  nonInstallation,
  toggleNonInstallation,
  setNonInstallationReason,
}) => {
  return (
    <fieldset className="non-installation">
      <Checkbox
        checked={nonInstallation.reponse}
        onChange={toggleNonInstallation}
        label="Non installation"
      />
      {nonInstallation.reponse && (
        <div className="non-installation__reason">
          <label htmlFor="motifNonInstallation">
            Motif de non-installation
          </label>
          <textarea
            id="motifNonInstallation"
            type="text"
            name="non_installation.motif"
            placeholder="Indiquer le motif"
            value={nonInstallation.motif}
            onChange={(e) => setNonInstallationReason(e.target.value)}
            cols={3}
          />
          <em>
            (Non conformité des normes de sécurité, Accessibilité, Demande du
            client...)
          </em>
        </div>
      )}
    </fieldset>
  );
};

NonInstallation.propTypes = {
  nonInstallation: PropTypes.object.isRequired,
  toggleNonInstallation: PropTypes.func.isRequired,
  setNonInstallationReason: PropTypes.func.isRequired,
};

export default NonInstallation;
