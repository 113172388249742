import React from "react";
import PropTypes from "prop-types";

const PaymentMethod = ({ types }) => {
  const mapping = { cash: "espèces", check: "chèque", other: "autre" };
  const paymentMethods = types.map((type) => mapping[type] || "forme inconnue");
  return <span>{paymentMethods.join(" et ")}</span>;
};

PaymentMethod.propTypes = {
  types: PropTypes.array.isRequired,
};

const computeTotalAmount = (payments) =>
  payments.reduce((acc, payment) => acc + Number(payment.paid_amount), 0);

const getPaymentMethods = (payments) =>
  payments.map((payment) => payment.payment_method);

const ConfirmationModal = ({
  delivery,
  payments,
  SAVComment,
  validateDelivery,
  setShouldAskBeforeLeavingDelivery,
}) => {
  const clientPrice = delivery.order.prices.client_price;
  const storeSettle = delivery.order.prices.store_settle;
  const clientPricePayments = payments.filter(
    (payment) => payment.type === "client_price"
  );
  const storeSettlePayments = payments.filter(
    (payment) => payment.type === "store_settle"
  );
  const totalPaidAmountClientPrice = computeTotalAmount(clientPricePayments);
  const paymentMethodsClientPrice = getPaymentMethods(clientPricePayments);
  const totalPaidAmountStoreSettle = computeTotalAmount(storeSettlePayments);
  const paymentMethodsStoreSettle = getPaymentMethods(storeSettlePayments);

  const handleValidateDelivery = () => {
    //don't active prompt guard if user validate
    setShouldAskBeforeLeavingDelivery(false);

    validateDelivery(true);
    return;
  };

  return (
    <div className="confirm-modal">
      <header className="header">
        <div className="row">
          <div className="column">Confirmation</div>
        </div>
      </header>

      <div className="contain">
        <div className="row">
          <div className="column">
            <p>
              Etes-vous sûr de vouloir déclarer cette livraison comme terminée ?
            </p>

            {+clientPrice > 0 || totalPaidAmountClientPrice > 0 ? (
              <section>
                Le montant à percevoir à la livraison était : {+clientPrice}
                &nbsp;&euro;
                {+clientPrice !== +totalPaidAmountClientPrice ? (
                  <div>
                    Le montant perçu est : {+totalPaidAmountClientPrice}
                    {paymentMethodsClientPrice && (
                      <>
                        &nbsp;&euro; en&nbsp;
                        <PaymentMethod types={paymentMethodsClientPrice} />
                      </>
                    )}
                    <div className="warning">
                      Attention: les montants ne semblent pas correspondre
                    </div>
                  </div>
                ) : (
                  <p>
                    Ce même montant a été perçu en&nbsp;
                    <PaymentMethod types={paymentMethodsClientPrice} />
                  </p>
                )}
              </section>
            ) : null}

            {+storeSettle > 0 || totalPaidAmountStoreSettle > 0 ? (
              <section>
                Le montant à percevoir pour le solde magasin était :&nbsp;
                {+storeSettle}
                &nbsp;&euro;
                {+storeSettle !== +totalPaidAmountStoreSettle ? (
                  <div>
                    Le montant perçu est : {+totalPaidAmountStoreSettle}
                    &nbsp;&euro; en&nbsp;
                    <PaymentMethod types={paymentMethodsStoreSettle} />
                    <div className="warning">
                      Attention: les montants ne semblent pas correspondre
                    </div>
                  </div>
                ) : (
                  <p>
                    Ce même montant a été perçu en&nbsp;
                    <PaymentMethod types={paymentMethodsStoreSettle} />
                  </p>
                )}
              </section>
            ) : null}

            {SAVComment ? (
              <p>
                Le message suivant sera envoyé au SAV : <q>{SAVComment}</q>
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <footer className="footer-section">
        <div className="row">
          <div className="column">
            <button className="button" onClick={() => validateDelivery(false)}>
              Annuler
            </button>
            <button className="button" onClick={handleValidateDelivery}>
              Valider
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

ConfirmationModal.propTypes = {
  delivery: PropTypes.shape({
    order: PropTypes.shape({
      prices: PropTypes.shape({
        client_price: PropTypes.number,
        store_settle: PropTypes.number,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  payments: PropTypes.array.isRequired,
  SAVComment: PropTypes.string,
  validateDelivery: PropTypes.func.isRequired,
  setShouldAskBeforeLeavingDelivery: PropTypes.func.isRequired,
};

export default ConfirmationModal;
