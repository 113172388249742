import React from "react";
import PropTypes from "prop-types";
import useClickOutsideListener from "utils/useClickOutsideListener";

const Tooltip = ({ children, text }) => {
  const [visible, setVisible] = React.useState(false);

  const tooltipRef = React.useRef();
  useClickOutsideListener(tooltipRef, () => setVisible(false));

  const toggleTooltip = () => {
    setVisible(!visible);
  };

  if (!text) {
    return children;
  }

  return (
    <div ref={tooltipRef} className="tooltip" onClick={toggleTooltip}>
      {children}
      {visible && <div className="tooltip__text">{text}</div>}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tooltip;
