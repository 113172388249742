import { categoryGasCooking, categoriesPluggableByClient } from "utils/config";

const mustHaveOutletPlug = (bouchon_laiton_sur_arrivee_non_utilisee) =>
  bouchon_laiton_sur_arrivee_non_utilisee === "Non";

const mustReplaceFiberSeal = (joint_fibre_remplace_si_bouchon_deplace) =>
  joint_fibre_remplace_si_bouchon_deplace === "Non";

const mustControlSeal = (seal) => seal === "Non";

const isDisplayedPluggedByClient = (articleCategories) =>
  articleCategories.some((category) =>
    categoriesPluggableByClient.includes(category.toLowerCase())
  );

const isDisplayedGasCookingSecurityCheck = (articleCategories) =>
  articleCategories.some(
    (category) => categoryGasCooking.toLowerCase() === category.toLowerCase()
  );

const isDisplayedSecurity = (articleCategories, withoutInstallation = false) =>
  (isDisplayedPluggedByClient(articleCategories) ||
    isDisplayedGasCookingSecurityCheck(articleCategories)) &&
  !withoutInstallation;

const isDisplayedFiberSealReplacement = (
  bouchon_laiton_sur_arrivee_non_utilisee
) => bouchon_laiton_sur_arrivee_non_utilisee === "Oui";

const isDisplayedSealingControl = (
  joint_fibre_remplace_si_bouchon_deplace,
  bouchon_laiton_sur_arrivee_non_utilisee
) =>
  (["Oui", "Le bouchon était dans le sachet d’accessoires"].includes(
    joint_fibre_remplace_si_bouchon_deplace
  ) &&
    bouchon_laiton_sur_arrivee_non_utilisee !== "Non") ||
  ["Pas de deuxième arrivée", "Le bouchon était déjà en place"].includes(
    bouchon_laiton_sur_arrivee_non_utilisee
  );

const isDisplayedSealingBrassSeal = (bouchon_laiton_sur_arrivee_non_utilisee) =>
  bouchon_laiton_sur_arrivee_non_utilisee !== "Pas de deuxième arrivée";

export {
  mustHaveOutletPlug,
  mustReplaceFiberSeal,
  mustControlSeal,
  isDisplayedPluggedByClient,
  isDisplayedGasCookingSecurityCheck,
  isDisplayedSecurity,
  isDisplayedFiberSealReplacement,
  isDisplayedSealingControl,
  isDisplayedSealingBrassSeal,
};
