export const getValidationErrors = yupValidationErrors => {
  return yupValidationErrors.reduce((errors, currentError) => {
    const section = currentError.path.split(".")[0];

    return {
      ...errors,
      [section]: errors[section]
        ? [...errors[section], currentError.message]
        : [currentError.message]
    };
  }, {});
};
