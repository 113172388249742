import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Calendar from "react-calendar";
import useClickOutsideListener from "utils/useClickOutsideListener";
import "react-calendar/dist/Calendar.css";

const ModalCalendar = ({ isOpen, setIsOpen, value, onChange, usedDates }) => {
  const calendarWrapperRef = React.useRef();
  useClickOutsideListener(calendarWrapperRef, () => setIsOpen(false));

  const modalClasses = classnames("modal-calendar", { open: isOpen });

  const tileDisabled = ({ date }) => {
    return usedDates.some(
      (usedDate) =>
        usedDate.getDate() === date.getDate() &&
        usedDate.getMonth() === date.getMonth() &&
        usedDate.getYear() === date.getYear()
    );
  };

  return (
    <div className={modalClasses}>
      <div className="modal-calendar__calendar">
        <div ref={calendarWrapperRef} className="modal-calendar__wrapper">
          <Calendar
            tileDisabled={tileDisabled}
            onChange={(value) => onChange(value)}
            value={value}
          />
        </div>
      </div>
    </div>
  );
};

ModalCalendar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  usedDates: PropTypes.array.isRequired,
};

export default ModalCalendar;
