import React from "react";
import PropTypes from "prop-types";
import { utcNow } from "utils/date";

const DeliveryActions = ({ validations, display, actions, formState }) => (
  <div className="delivery-actions">
    <div className="delivery-actions__truck-change">
      <button
        disabled={validations.isValidated}
        type="button"
        onClick={() => actions.setShowTruckChange(true)}
      >
        Autre camion
      </button>
    </div>
    <div className="delivery-actions__right-container">
      <button
        disabled={validations.isValidated || formState.arrivalTime}
        type="button"
        className="delivery-actions__client-arrival"
        onClick={() => actions.setArrivalTime(utcNow())}
      >
        Arrivée client
      </button>
      <button
        disabled={validations.isValidated}
        type="button"
        className="delivery-actions__delivery-failed"
        onClick={() => {
          if (!display.isDeliveryFailed) {
            actions.resetOrderArticles();
          }

          actions.setIsDeliveryFailed(!display.isDeliveryFailed);

          actions.qualitySheetActions.setIsCompliant(
            !formState.qualitySheet.isCompliant
          );
        }}
      >
        {display.isDeliveryFailed ? "Annuler échec" : "Échec livraison"}
      </button>
    </div>
  </div>
);

DeliveryActions.propTypes = {
  actions: PropTypes.shape({
    qualitySheetActions: PropTypes.shape({
      setIsCompliant: PropTypes.func,
    }),
    resetOrderArticles: PropTypes.func,
    setIsDeliveryFailed: PropTypes.func,
    setShowTruckChange: PropTypes.func,
    setArrivalTime: PropTypes.func,
  }),
  display: PropTypes.shape({
    isDeliveryFailed: PropTypes.bool,
  }),
  formState: PropTypes.shape({
    qualitySheet: PropTypes.shape({
      isCompliant: PropTypes.bool,
    }),
    arrivalTime: PropTypes.bool,
  }),
  validations: PropTypes.shape({
    isValidated: PropTypes.bool,
  }),
};

export default DeliveryActions;
