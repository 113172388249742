import { gql } from "@apollo/client";

export const makeStartReturnArticles = (orderId, formValues) => ({
  variables: {
    input: {
      order_id: orderId,
      articles: formValues
        .filter((formValue) => formValue.checked)
        .map((formValue) => ({
          name: formValue.name,
          quantity: formValue.quantity,
        })),
    },
  },
  context: {
    offline: false,
  },
});

export const RETURN_ARTICLES = gql`
  mutation returnArticles($input: ReturnArticlesInput!) {
    returnArticles(input: $input) {
      success
      message
    }
  }
`;
