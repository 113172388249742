import { useReducer } from "react";

const SET_COMPLIANT = "SET_COMPLIANT";
const SET_PACKAGE_COUNT = "SET_NOMBRE_COLIS";
const SET_SATISFACTION = "SET_SATISFACTION";
const SET_INTERVENTION = "SET_INTERVENTION";
const SET_SECURITY = "SET_SECURITY";
const RESET_SECURITY_BELOW_TWO_GAS_SUPPLY =
  "RESET_SECURITY_BELOW_TWO_GAS_SUPPLY";
const RESET_SECURITY_BELOW_BRASS_SEAL_ON_NON_USED_SUPPLY =
  "RESET_SECURITY_BELOW_BRASS_SEAL_ON_NON_USED_SUPPLY";
const TOGGLE_DISCLAIMER = "TOGGLE_DISCLAIMER";
const SET_DISCLAIMER_USER_INPUTS = "SET_DISCLAIMER_USER_INPUTS";
const TOGGLE_NON_INSTALLATION = "TOGGLE_NON_INSTALLATION";
const SET_NON_INSTALLATION_REASON = "SET_NON_INSTALLATION_REASON";
const SET_SIGNATURE = "SET_SIGNATURE";
const TOGGLE_CONFIRMATION = "TOGGLE_CONFIRMATION";
const RESET_QUALITY_SHEET = "RESET_QUALITY_SHEET";
const TOGGLE_BYPASS = "TOGGLE_BYPASS";
const SET_BYPASS_REASON = "SET_BYPASS_REASON";

const initialState = {
  orderId: null,
  isCompliant: true,
  bypass: {
    reponse: false,
    motif: "",
  },
  nombre_colis: "",
  satisfaction: {
    presentation_livreurs: null,
    respect_interieur: null,
    respect_rdv: null,
    prestation: null,
    montage: null,
  },
  intervention: {
    deballage_effectue: null,
    controle_emballage: null,
    reprise_emballage: null,
    port_masque: null,
    lavage_main: null,
  },
  security: {
    changement_gicleurs: {
      gicleur_remplace_par_technicien: null,
    },
    appareils_deux_arrivees: {
      bouchon_laiton_sur_arrivee_non_utilisee: null,
      joint_fibre_remplace_si_bouchon_deplace: null,
    },
    controle_etancheite: {
      tuyau_gaz: null,
      bouchon_laiton: null,
    },
    controle_branchement: {
      controle_branchement_par_client: null,
    },
  },
  decharge_responsabilite: {
    reponse: false,
    nom: "",
    produits: "",
  },
  non_installation: {
    reponse: false,
    motif: "",
  },
  signature: null,
  confirmation: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_COMPLIANT:
      return { ...state, isCompliant: action.payload };

    case SET_PACKAGE_COUNT:
      return { ...state, nombre_colis: action.payload };

    case SET_SATISFACTION:
      return {
        ...state,
        satisfaction: {
          ...state.satisfaction,
          [action.payload.category]: action.payload.rate,
        },
      };

    case SET_INTERVENTION:
      return {
        ...state,
        intervention: {
          ...state.intervention,
          [action.payload.action]: action.payload.isDone,
        },
      };

    case SET_SECURITY:
      return {
        ...state,
        security: {
          ...state.security,
          [action.payload.category]: {
            ...state.security[action.payload.category],
            [action.payload.inspectionPoint]: action.payload.status,
          },
        },
      };

    case RESET_SECURITY_BELOW_TWO_GAS_SUPPLY:
      return {
        ...state,
        security: {
          ...state.security,
          appareils_deux_arrivees: {
            ...state.security.bouchon_laiton_sur_arrivee_non_utilisee,
            joint_fibre_remplace_si_bouchon_deplace: null,
          },
          controle_etancheite: { tuyau_gaz: null, bouchon_laiton: null },
        },
      };

    case RESET_SECURITY_BELOW_BRASS_SEAL_ON_NON_USED_SUPPLY:
      return {
        ...state,
        security: {
          ...state.security,

          controle_etancheite: { tuyau_gaz: null, bouchon_laiton: null },
        },
      };

    case TOGGLE_DISCLAIMER:
      return {
        ...state,
        decharge_responsabilite: {
          ...state.decharge_responsabilite,
          reponse: !state.decharge_responsabilite.reponse,
        },
      };

    case SET_DISCLAIMER_USER_INPUTS:
      return {
        ...state,
        decharge_responsabilite: {
          ...state.decharge_responsabilite,
          [action.payload.name]: action.payload.value,
        },
      };

    case TOGGLE_NON_INSTALLATION:
      return {
        ...state,
        non_installation: {
          ...state.non_installation,
          reponse: !state.non_installation.reponse,
        },
      };

    case SET_NON_INSTALLATION_REASON:
      return {
        ...state,
        non_installation: {
          ...state.non_installation,
          motif: action.payload,
        },
      };

    case TOGGLE_BYPASS:
      return {
        ...state,
        bypass: {
          ...state.bypass,
          reponse: !state.bypass.reponse,
        },
      };

    case SET_BYPASS_REASON:
      return {
        ...state,
        bypass: {
          ...state.bypass,
          motif: action.payload,
        },
      };

    case SET_SIGNATURE:
      return { ...state, signature: action.payload.signature };

    case TOGGLE_CONFIRMATION:
      return { ...state, confirmation: !state.confirmation };

    case RESET_QUALITY_SHEET:
      return { ...initialState, orderId: action.payload.orderId };

    default:
      return state;
  }
};

const useQualitySheet = () => {
  const [
    {
      isCompliant,
      bypass,
      nombre_colis,
      satisfaction,
      intervention,
      decharge_responsabilite,
      non_installation,
      signature,
      confirmation,
      security,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const setIsCompliant = (isCompliant) =>
    dispatch({ type: SET_COMPLIANT, payload: isCompliant });

  const setPackageCount = (count) =>
    dispatch({ type: SET_PACKAGE_COUNT, payload: count });

  const setSatisfaction = (category, rate) => {
    const resetMontage =
      category === "montage" && satisfaction.montage === rate;

    dispatch({
      type: SET_SATISFACTION,
      payload: { category, rate: resetMontage ? null : rate },
    });
  };

  const setIntervention = (action, isDone) =>
    dispatch({ type: SET_INTERVENTION, payload: { action, isDone } });

  const setSecurity = ({ category, inspectionPoint, status }) => {
    if (inspectionPoint === "bouchon_laiton_sur_arrivee_non_utilisee") {
      dispatch({ type: RESET_SECURITY_BELOW_TWO_GAS_SUPPLY });
    }

    if (inspectionPoint === "joint_fibre_remplace_si_bouchon_deplace") {
      dispatch({ type: RESET_SECURITY_BELOW_BRASS_SEAL_ON_NON_USED_SUPPLY });
    }

    return dispatch({
      type: SET_SECURITY,
      payload: { category, inspectionPoint, status },
    });
  };

  const toggleDisclaimer = () => dispatch({ type: TOGGLE_DISCLAIMER });

  const setDisclaimerUserInput = (name, value) =>
    dispatch({ type: SET_DISCLAIMER_USER_INPUTS, payload: { name, value } });

  const toggleNonInstallation = () =>
    dispatch({ type: TOGGLE_NON_INSTALLATION });

  const setNonInstallationReason = (reason) =>
    dispatch({ type: SET_NON_INSTALLATION_REASON, payload: reason });

  const setSignature = (signature) =>
    dispatch({ type: SET_SIGNATURE, payload: { signature } });

  const toggleConfirmation = () => dispatch({ type: TOGGLE_CONFIRMATION });

  const toggleBypass = () => dispatch({ type: TOGGLE_BYPASS });

  const setBypassReason = (reason) =>
    dispatch({ type: SET_BYPASS_REASON, payload: reason });

  return [
    {
      isCompliant,
      bypass,
      nombre_colis,
      satisfaction,
      intervention,
      decharge_responsabilite,
      non_installation,
      signature,
      confirmation,
      security,
    },
    {
      setIsCompliant,
      setBypassReason,
      setPackageCount,
      setSatisfaction,
      setIntervention,
      setSecurity,
      toggleDisclaimer,
      setDisclaimerUserInput,
      toggleNonInstallation,
      setNonInstallationReason,
      setSignature,
      toggleConfirmation,
      toggleBypass,
    },
  ];
};

export default useQualitySheet;
