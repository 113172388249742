import React from "react";
import generateUUID from "utils/generateUUID";
import {
  getAttributeValueByPath,
  setAttributeValueByPath,
} from "utils/objectUtils";
import useAuth from "utils/useAuth";
import { useMutation } from "@apollo/client";
import { SYNCHRONIZE_PICKING } from "./synchronizePicking";

export const DEFAULT_PICKING_ARTICLE_DATA = {
  pickup_at: null,
  pickup_by: null,
  pickup_status: null,
  pickup_line: null,
  pickup_incident_reason: null,
  pickup_photos: [],
};

const PICKUP_STATUS = {
  PICKUP_OK: {
    value: "PICKUP_OK",
    label: "Prélèvement réussi",
  },
  PICKUP_FAILED: { value: "PICKUP_FAILED", label: "Prélèvement échoué" },
  PICKUP_PARTIALLY: { value: "PICKUP_PARTIALLY", label: "Prélèvement partiel" },
};

const PICKUP_INCIDENTS = {
  DAMAGED_PACKAGING_OR_PRODUCT: {
    value: "DAMAGED_PACKAGING_OR_PRODUCT",
    label: "Emballage/produit abîmé",
  },
  MISSING_PRODUCT: { value: "MISSING_PRODUCT", label: "Produit manquant" },
  PRODUCT_OR_EAN_DOES_NOT_MATCH: {
    value: "PRODUCT_OR_EAN_DOES_NOT_MATCH",
    label: "Produit/EAN ne correspond pas",
  },
  FORCED: { value: "FORCED", label: "Forcé" },
  EXHIBITION_PRODUCT: {
    value: "EXHIBITION_PRODUCT",
    label: "Produit d'exposition",
  },
};

const ALLOWED_NUMBER_OF_PHOTO_BY_ARTICLE = 2;

const generateArticleMetadata = (article, articlePath) => {
  const numberOfMetadataToBeGenerated = article.qty - article.metadata.length;

  const alreadyGeneratedMetadata = article.metadata.map((metadatum) => ({
    ...metadatum,
    pickup_photos: metadatum.pickup_photos.map((photo) => ({ base64: photo })),
    alreadySynchronized: true,
  }));

  /* 
    There is (the same amount of/more) metadata than the current article quantity,
    in this case we will return the metadata already contained in article and
    tag them as already synchronized
  */
  if (numberOfMetadataToBeGenerated <= 0) {
    return alreadyGeneratedMetadata;
  }

  /* 
    Some metadata are already generated/synchronized but we miss some,
    in this case we will return the already generated metadata and generate the 
    missing ones
  */
  if (
    numberOfMetadataToBeGenerated > 0 &&
    alreadyGeneratedMetadata.length > 0
  ) {
    return [
      ...Array.apply(null, Array(numberOfMetadataToBeGenerated)).map(
        (_, i) => ({
          ...DEFAULT_PICKING_ARTICLE_DATA,
          path: `${articlePath}.metadata[${i}]`,
          id: generateUUID(),
          alreadySynchronized: false,
        })
      ),
      ...alreadyGeneratedMetadata,
    ];
  }

  /* There is not metadata associated with the article : we generate them all */
  return Array.apply(null, Array(article.qty)).map((_, i) => ({
    ...DEFAULT_PICKING_ARTICLE_DATA,
    path: `${articlePath}.metadata[${i}]`,
    id: generateUUID(),
    alreadySynchronized: false,
  }));
};

const getMaxNumberOfPhotos = (orderArticles) => {
  const maxNumberOfPhotos = orderArticles.reduce((max, orderArticle) => {
    return (
      max + orderArticle.metadata.length * ALLOWED_NUMBER_OF_PHOTO_BY_ARTICLE
    );
  }, 0);

  return maxNumberOfPhotos;
};

const addMetadataToOrderArticles = (order, dayIndex, halfDay, orderIndex) => {
  const orderPath = `[${dayIndex}].${halfDay}[${orderIndex}]`;

  const orderArticles = order.order_articles.map((article, articleIndex) => {
    const articlePath = `${orderPath}.order_articles[${articleIndex}]`;

    return {
      ...article,
      metadata: generateArticleMetadata(article, articlePath),
    };
  });

  let numberOfPhotos = 0;
  orderArticles.forEach((article) => {
    article.metadata.forEach((metadatum) => {
      numberOfPhotos += metadatum.pickup_photos.length;
    });
  });

  return {
    ...order,
    numberOfPhotos: order.numberOfPhotos ?? numberOfPhotos,
    maxNumberOfPhotos: getMaxNumberOfPhotos(orderArticles),
    path: orderPath,
    order_articles: orderArticles,
  };
};

const formatPicking = (pickingData) => {
  const formattedPickingData = pickingData.map((pickingDatum, dayIndex) => {
    let am = pickingDatum?.am;
    if (am?.length > 0) {
      am = am.map((order, orderIndex) => {
        return addMetadataToOrderArticles(order, dayIndex, "am", orderIndex);
      });
    }

    let pm = pickingDatum?.pm;
    if (pm.length > 0) {
      pm = pm.map((order, orderIndex) => {
        return addMetadataToOrderArticles(order, dayIndex, "pm", orderIndex);
      });
    }

    return { ...pickingDatum, am, pm };
  });

  return formattedPickingData;
};

const makeSynchronizePicking = (orderComments, orderArticlesMetadata) => ({
  variables: {
    input: {
      orders_comments: orderComments,
      order_articles_metadata: orderArticlesMetadata,
    },
  },
});

const removePathAttributeFromMetadata = (metadata) => {
  /* Clone the metadata to prevent original array mutation */
  const clonedMetadata = metadata.map((metadatum) => ({ ...metadatum }));
  return clonedMetadata.map((metadatum) => {
    delete metadatum.path;
    return metadatum;
  });
};

const usePicking = (pickingData) => {
  const [currentPickingInfos, setCurrentPickingInfos] = React.useState([]);
  const [metadataToSynchronize, setMetadataToSynchronize] = React.useState([]);
  const [
    ordersCommentsToSynchronize,
    setOrdersCommentsToSynchronize,
  ] = React.useState([]);
  const [
    synchronizePickingErrorMessage,
    setSynchronizePickingErrorMessage,
  ] = React.useState(null);

  const { user } = useAuth();

  const tagMetadataAsAlreadySynchronized = (metadata) => {
    const [...clonedCurrentPickingInfos] = currentPickingInfos;
    metadata.forEach((metadatum) => {
      setAttributeValueByPath(
        clonedCurrentPickingInfos,
        `${metadatum.path}.alreadySynchronized`,
        true
      );
    });
    setCurrentPickingInfos(clonedCurrentPickingInfos);
  };

  const [
    synchronizePickingMutation,
    { loading: synchronizePickingLoading, error: synchronizePickingError },
  ] = useMutation(SYNCHRONIZE_PICKING, {
    onCompleted: ({ synchronizePicking }) => {
      if (synchronizePicking.success) {
        const {
          metadataWithPickupStatus,
          metadataWithoutPickupStatus,
        } = metadataToSynchronize.reduce(
          (acc, metadatum) => {
            if (metadatum.pickup_status) {
              acc.metadataWithPickupStatus.push(metadatum);
              return acc;
            }
            acc.metadataWithoutPickupStatus.push(metadatum);
            return acc;
          },
          { metadataWithPickupStatus: [], metadataWithoutPickupStatus: [] }
        );
        /* Tag synchronized metadata as already synchronized */
        tagMetadataAsAlreadySynchronized(metadataWithPickupStatus);
        /* Flush sync stacks */
        setMetadataToSynchronize(metadataWithoutPickupStatus);
        setOrdersCommentsToSynchronize([]);
      }
      if (synchronizePicking.message) {
        setSynchronizePickingErrorMessage(synchronizePicking.message);
      }
    },
    onError: (error) => {
      setSynchronizePickingErrorMessage(error);
    },
  });

  React.useEffect(() => {
    setCurrentPickingInfos(formatPicking(pickingData));
  }, [pickingData]);

  const getOrderPathFromMetadataPath = (metadataPath) => {
    /*
      No check here because we can assume there will always be an order path if there is a metadata path.
      Note: metadataPath will look like this : [0].am[0].order_articles[0].metadata[0]
    */
    return metadataPath.split(".order_articles")[0];
  };

  const getArticlePathFromMetadataPath = (metadataPath) => {
    /*
      No check here because we can assume there will always be an article path if there is a metadata path.
      Note: metadataPath will look like this : [0].am[0].order_articles[0].metadata[0]
    */
    return metadataPath.split(".metadata")[0];
  };

  const addToMetadataToSynchronize = React.useCallback(
    (metadataPath, currentPickingInfos) => {
      const articlePath = getArticlePathFromMetadataPath(metadataPath);
      const article = getAttributeValueByPath(currentPickingInfos, articlePath);
      const metadata = getAttributeValueByPath(
        currentPickingInfos,
        metadataPath
      );

      const alreadyInSyncStackMetadataIndex = metadataToSynchronize.findIndex(
        (metadataToSync) => metadataToSync.metadata_id === metadata.id
      );

      const formattedMetadata = {
        path: metadataPath,
        order_article_id: article.id,
        metadata_id: metadata.id,
        pickup_at: new Date(),
        pickup_by: user.crewmates,
        pickup_status: metadata.pickup_status,
        pickup_incident_reason: metadata.pickup_incident_reason,
        pickup_photos: metadata.pickup_photos.map(({ base64 }) => base64),
      };

      /* If not already tagged for sync add it to the sync stack */
      if (alreadyInSyncStackMetadataIndex === -1) {
        setMetadataToSynchronize((toSynchronize) => [
          ...toSynchronize,
          formattedMetadata,
        ]);
      } else {
        const clonedMetadataToSynchronize = [...metadataToSynchronize];
        /* If the new metadata status is null we delete it */
        if (!metadata.pickup_status) {
          clonedMetadataToSynchronize.splice(
            alreadyInSyncStackMetadataIndex,
            1
          );
        } else {
          /* Else we replace it */
          clonedMetadataToSynchronize[
            alreadyInSyncStackMetadataIndex
          ] = formattedMetadata;
        }
        setMetadataToSynchronize(clonedMetadataToSynchronize);
      }
    },
    [metadataToSynchronize, setMetadataToSynchronize, user.crewmates]
  );

  const addOrderCommentToSynchronize = React.useCallback(
    (orderPath, currentPickingInfos) => {
      const order = getAttributeValueByPath(currentPickingInfos, orderPath);

      const alreadyInSyncStackOrderCommentIndex = ordersCommentsToSynchronize.findIndex(
        (orderCommentToSync) => orderCommentToSync.order_id === order.id
      );

      const formattedOrderComment = {
        order_id: order.id,
        comment: order.picking_comment,
      };

      /* If not already tagged for sync add it to the sync stack */
      if (alreadyInSyncStackOrderCommentIndex === -1) {
        setOrdersCommentsToSynchronize((toSynchronize) => [
          ...toSynchronize,
          formattedOrderComment,
        ]);
      } else {
        const clonedOrdersCommentsToSynchronize = [
          ...ordersCommentsToSynchronize,
        ];
        /* Else we replace it */
        clonedOrdersCommentsToSynchronize[
          alreadyInSyncStackOrderCommentIndex
        ] = formattedOrderComment;
      }
    },
    [ordersCommentsToSynchronize, setOrdersCommentsToSynchronize]
  );

  const updateNumberOfPhotos = React.useCallback(
    (pickingInfosRef, orderPath, deviation) => {
      const currentNumberOfPhotosPath = `${orderPath}.numberOfPhotos`;
      const currentNumberOfPhotos = getAttributeValueByPath(
        pickingInfosRef,
        currentNumberOfPhotosPath
      );

      const updatedNumberOfPhotos = currentNumberOfPhotos + deviation;

      setAttributeValueByPath(
        pickingInfosRef,
        currentNumberOfPhotosPath,
        updatedNumberOfPhotos
      );
    },
    []
  );

  const addArticlePickupPhoto = React.useCallback(
    (metadataPath, photo) => {
      let clonedCurrentPickingInfos = [...currentPickingInfos];

      if (!photo?.belongsToAnIncident) {
        const orderPath = getOrderPathFromMetadataPath(metadataPath);
        updateNumberOfPhotos(clonedCurrentPickingInfos, orderPath, 1);
      }

      const photosPath = `${metadataPath}.pickup_photos`;

      setAttributeValueByPath(
        clonedCurrentPickingInfos,
        photosPath,
        photo,
        true
      );

      setCurrentPickingInfos(clonedCurrentPickingInfos);
      addToMetadataToSynchronize(metadataPath, clonedCurrentPickingInfos);
    },
    [
      currentPickingInfos,
      setCurrentPickingInfos,
      updateNumberOfPhotos,
      addToMetadataToSynchronize,
    ]
  );

  const deleteArticlePickupPhoto = React.useCallback(
    (metadataPath, updatedPhotos, photoBelongsToAnIncident) => {
      let clonedCurrentPickingInfos = [...currentPickingInfos];

      if (!photoBelongsToAnIncident) {
        const orderPath = getOrderPathFromMetadataPath(metadataPath);
        updateNumberOfPhotos(clonedCurrentPickingInfos, orderPath, -1);
      }

      const photosPath = `${metadataPath}.pickup_photos`;
      setAttributeValueByPath(
        clonedCurrentPickingInfos,
        photosPath,
        updatedPhotos
      );

      setCurrentPickingInfos(clonedCurrentPickingInfos);
      addToMetadataToSynchronize(metadataPath, clonedCurrentPickingInfos);
    },
    [
      currentPickingInfos,
      setCurrentPickingInfos,
      updateNumberOfPhotos,
      addToMetadataToSynchronize,
    ]
  );

  const handleSuccessfulPickup = React.useCallback(
    (metadataPath) => {
      let clonedCurrentPickingInfos = [...currentPickingInfos];

      const pickupStatusPath = `${metadataPath}.pickup_status`;
      setAttributeValueByPath(
        clonedCurrentPickingInfos,
        pickupStatusPath,
        PICKUP_STATUS.PICKUP_OK.value
      );

      setCurrentPickingInfos(clonedCurrentPickingInfos);
      addToMetadataToSynchronize(metadataPath, clonedCurrentPickingInfos);
    },
    [currentPickingInfos, addToMetadataToSynchronize]
  );

  const resetPickupStatus = React.useCallback(
    (metadataPath, resetIncident = false) => {
      let clonedCurrentPickingInfos = [...currentPickingInfos];

      if (resetIncident) {
        const pickupPhotosPath = `${metadataPath}.pickup_photos`;
        const previousPickupPhotos = getAttributeValueByPath(
          clonedCurrentPickingInfos,
          pickupPhotosPath
        );

        setAttributeValueByPath(
          clonedCurrentPickingInfos,
          metadataPath,
          {
            pickup_incident_reason: null,
            pickup_status: null,
            pickup_photos: previousPickupPhotos.filter(
              (photo) => !photo?.belongsToAnIncident
            ),
          },
          false,
          true
        );

        setCurrentPickingInfos(clonedCurrentPickingInfos);
        addToMetadataToSynchronize(metadataPath, clonedCurrentPickingInfos);
        return;
      }

      const pickupStatusPath = `${metadataPath}.pickup_status`;
      setAttributeValueByPath(
        clonedCurrentPickingInfos,
        pickupStatusPath,
        null
      );

      setCurrentPickingInfos(clonedCurrentPickingInfos);
      addToMetadataToSynchronize(metadataPath, clonedCurrentPickingInfos);
    },
    [currentPickingInfos, addToMetadataToSynchronize]
  );

  const updatePickupIncidentReason = React.useCallback(
    (metadataPath, incidentReason, pickupSuccessful) => {
      let clonedCurrentPickingInfos = [...currentPickingInfos];

      setAttributeValueByPath(
        clonedCurrentPickingInfos,
        metadataPath,
        {
          pickup_incident_reason: incidentReason,
          ...(pickupSuccessful !== undefined && {
            pickup_status: pickupSuccessful
              ? PICKUP_STATUS.PICKUP_OK.value
              : PICKUP_STATUS.PICKUP_FAILED.value,
          }),
        },
        false,
        true
      );

      setCurrentPickingInfos(clonedCurrentPickingInfos);
      addToMetadataToSynchronize(metadataPath, clonedCurrentPickingInfos);
    },
    [currentPickingInfos, addToMetadataToSynchronize]
  );

  const updateOrderComment = React.useCallback(
    (orderPath, comment) => {
      let clonedCurrentPickingInfos = [...currentPickingInfos];

      const orderCommentPath = `${orderPath}.picking_comment`;

      setAttributeValueByPath(
        clonedCurrentPickingInfos,
        orderCommentPath,
        comment
      );

      setCurrentPickingInfos(clonedCurrentPickingInfos);
      addOrderCommentToSynchronize(orderPath, clonedCurrentPickingInfos);
    },
    [currentPickingInfos, addOrderCommentToSynchronize]
  );

  const synchronizePicking = React.useCallback(
    (truckLineName) => {
      setSynchronizePickingErrorMessage(null);

      if (ordersCommentsToSynchronize.length || metadataToSynchronize.length) {
        const sanitazedMetadata = removePathAttributeFromMetadata(
          metadataToSynchronize
        );

        const onlyMetadataWithPickupStatus = sanitazedMetadata.filter(
          (metadatum) => metadatum.pickup_status
        );

        const metadataWithPickupLine = onlyMetadataWithPickupStatus.map(
          (metadata) => ({ ...metadata, pickup_line: truckLineName })
        );

        synchronizePickingMutation(
          makeSynchronizePicking(
            ordersCommentsToSynchronize,
            metadataWithPickupLine
          )
        );
      }
    },
    [
      metadataToSynchronize,
      ordersCommentsToSynchronize,
      synchronizePickingMutation,
      setSynchronizePickingErrorMessage,
    ]
  );

  const getOnlyMetadataWithPickupStatus = React.useCallback(() => {
    return metadataToSynchronize.filter((metadatum) => metadatum.pickup_status);
  }, [metadataToSynchronize]);

  return {
    currentPickingInfos,
    metadataToSynchronize: getOnlyMetadataWithPickupStatus(),
    ordersCommentsToSynchronize,
    addArticlePickupPhoto,
    deleteArticlePickupPhoto,
    handleSuccessfulPickup,
    resetPickupStatus,
    updatePickupIncidentReason,
    updateOrderComment,
    synchronizePicking,
    synchronizePickingErrorMessage,
    synchronizePickingLoading,
    synchronizePickingError,
  };
};

export { PICKUP_STATUS, PICKUP_INCIDENTS };
export default usePicking;
