const turnOnTorchLight = async (stream) => {
  const [track] = stream.getVideoTracks();

  if (track instanceof MediaStreamTrack) {
    try {
      const imageCapture = new ImageCapture(track);
      const photoCapabilities = await imageCapture.getPhotoCapabilities();

      if (
        photoCapabilities.fillLightMode &&
        photoCapabilities.fillLightMode.includes("flash")
      ) {
        await track.applyConstraints({
          advanced: [{ torch: true }],
        });
      }
    } catch (err) {
      /* If error we try to force the constraint in last ressort */
      try {
        await track.applyConstraints({
          advanced: [{ torch: true }],
        });
      } catch (err) {
        /* Not supported ? */
      }
    }
  }
};

/**
 * We don't do any check to turn off the "flash light" because if it's on it means that the features are supported
 */
const turnOffTorchLight = async (stream) => {
  const track = stream?.getVideoTracks()?.[0];

  if (track instanceof MediaStreamTrack) {
    try {
      await track.applyConstraints({
        advanced: [{ torch: false }],
      });
    } catch (err) {
      /* Something went wrong */
    }
  }
};

export { turnOnTorchLight, turnOffTorchLight };
