import { gql } from "@apollo/client";
import { ROADMAP_FRAGMENT } from "pages/Round/roadmapQuery";

export const CHANGE_TRUCK = gql`
  ${ROADMAP_FRAGMENT}

  mutation changeTruck($code: String!, $newCode: String!, $orderId: ID!) {
    changeTruck(code: $code, newCode: $newCode, orderId: $orderId) {
      success
      message
      roadmap {
        ...RoadmapFragment
      }
    }
  }
`;

export const makeChangeTruckOptions = (wantedCode, orderId, roadmap) => ({
  variables: {
    code: roadmap.code,
    newCode: wantedCode,
    orderId,
  },
  context: {
    offline: true,
  },
});
