import React, { useState } from "react";
import PropTypes from "prop-types";
import getKeyByValue from "utils/getKeyByValue";
import ReturnedArticleModal from "../ReturnedArticleModal";
import DeliveryItem from "../DeliveryItem";
import PickupItem from "../PickupItem";

const TaskList = ({
  items,
  halfDay,
  isReordering,
  positions,
  updatePosition,
  roadmap,
}) => {
  const [modalReturnModalIsOpen, setModalReturnModalIsOpen] = useState(null);
  const [ordersReturned, setOrdersReturned] = useState([]);

  const itemsWithPositions = items
    .map((item) => ({
      ...item,
      position: getKeyByValue(positions[halfDay], item.id),
    }))
    .filter((item) =>
      item.__typename === "RoadmapContractor" && item.is_auto_generated
        ? false
        : true
    )
    .sort((a, b) => a.position - b.position);

  return (
    <>
      {modalReturnModalIsOpen && (
        <ReturnedArticleModal
          closeModal={() => setModalReturnModalIsOpen(null)}
          order={modalReturnModalIsOpen.item.order}
          itemId={modalReturnModalIsOpen.item.id}
          setOrderReturned={(id) =>
            setOrdersReturned([...new Set([...ordersReturned, id])])
          }
        />
      )}

      {itemsWithPositions.map((item) => {
        if (item.__typename === "RoadmapContractor") {
          return (
            <PickupItem
              key={`pickup-item-${item.id}`}
              item={item}
              halfDay={halfDay}
              isReordering={isReordering}
              updatePosition={updatePosition}
              isLastItem={Number(item.position) === itemsWithPositions.length}
            />
          );
        }

        const correspondingPickingId = items
          .filter((item) => item.__typename === "RoadmapContractor")
          .find(
            (pickingItem) =>
              pickingItem.id.split("_-_")[0] === item.order.contractor.id
          )?.id;

        return (
          <DeliveryItem
            item={item}
            key={item.id}
            isReordering={isReordering}
            isLastItem={Number(item.position) === itemsWithPositions.length}
            halfDay={halfDay}
            roundStarted={roadmap.started[halfDay]}
            positions={positions[halfDay]}
            isOrderReturned={ordersReturned.includes(item.id)}
            updatePosition={updatePosition}
            setModalReturnModalIsOpen={setModalReturnModalIsOpen}
            correspondingPickingId={correspondingPickingId}
          />
        );
      })}
    </>
  );
};

TaskList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string,
      customerIsAbsent: PropTypes.bool,
      delivered: PropTypes.string,
      isDelayed: PropTypes.bool,
      isSmsSent: PropTypes.bool,
      someArticleHaveNotBeenDelivered: PropTypes.bool,
      id: PropTypes.string.isRequired,
      order: PropTypes.shape({
        last_event: PropTypes.string,
        articles: PropTypes.arrayOf(
          PropTypes.shape({
            article: PropTypes.shape({
              install_article: PropTypes.bool,
            }).isRequired,
          }).isRequired
        ).isRequired,
        contractor: PropTypes.shape({
          identity: PropTypes.shape({ code: PropTypes.string.isRequired })
            .isRequired,
        }).isRequired,
        reference: PropTypes.string.isRequired,
        store_return: PropTypes.bool.isRequired,
        client: PropTypes.shape({
          identity: PropTypes.shape({ lastname: PropTypes.string.isRequired })
            .isRequired,
        }).isRequired,
      }),
    })
  ),
  positions: PropTypes.object.isRequired,
  updatePosition: PropTypes.func.isRequired,
  halfDay: PropTypes.string.isRequired,
  isReordering: PropTypes.bool,
  roadmap: PropTypes.shape({
    started: PropTypes.object.isRequired,
  }).isRequired,
};

export default TaskList;
