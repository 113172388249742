import React from "react";
import { useHistory } from "react-router-dom";

import useAuth from "utils/useAuth";

const Settings = () => {
  const history = useHistory();
  const { user, logout } = useAuth();

  return (
    <div className="settings-page">
      <section className="settings-section">
        <label className="title">Informations</label>
        <dl className="definition-list">
          <dt className="term">Connecté en tant que :</dt>
          <dd className="definition">{user?.code}</dd>

          <dt className="term">Agence :</dt>
          <dd className="definition">{user?.officeName}</dd>

          <dt className="term">Équipe :</dt>
          <dd className="definition">{user?.crewmates}</dd>

          <dt className="term">Camion :</dt>
          <dd className="definition">{user?.truck_name}</dd>

          <dt className="term">Connecté le : </dt>
          <dd className="definition">{user?.login_at}</dd>
        </dl>
        <button
          onClick={() => {
            logout();
            history.push("/");
          }}
        >
          Se déconnecter
        </button>
      </section>
    </div>
  );
};

export default Settings;
