export const customerToNotify = 2;
export const cancelScreenTimeout = 3000;
export const categoryGasCooking = "appareils de cuisson gaz";
export const categoriesPluggableByClient = [
  "blanc",
  "brun",
  "sav blanc/brun",
  "electromenager",
];
export const pickingPhotosPath = `${process.env.REACT_APP_CAKE_URI}/img/picking/`;
