import React from "react";
import PropTypes from "prop-types";
import RcCheckbox from "rc-checkbox";

const Checkbox = ({ disabled, label, checked, onChange }) => {
  return (
    <div
      className="checkbox"
      role="checkbox"
      aria-checked={checked ? "true" : "false"}
      tabIndex="0"
      onClick={onChange}
    >
      <RcCheckbox checked={checked} disabled={disabled} />
      {label && <span className="checkbox__label">{label}</span>}
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
