import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PickupItem = ({
  item,
  halfDay,
  isReordering,
  updatePosition,
  isLastItem,
}) => {
  return (
    <Link
      to={`/picking/${item.id}`}
      onClick={(e) => {
        if (isReordering) {
          e.preventDefault();
        }
      }}
    >
      <div className="pickup-item">
        <div className="pickup-item__top-container">
          <div className="pickup-item__store">
            <b>Magasin</b> : {item.code}
          </div>
          <div className="pickup-item__reference">
            <b>Référence</b> : CHARGEMENT {item.name}
          </div>
        </div>
        <div className="pickup-item__bottom-container">
          <i className="fa fa-dolly" aria-hidden="true" />
        </div>
        <i
          className={`fa fa-chevron-right ${isReordering ? "hidden" : ""}`}
          aria-hidden="true"
        />
        {isReordering && (
          <div>
            {Number(item.position) !== 1 && (
              <button
                type="button"
                className="reorder -up button button-outline"
                onClick={updatePosition({
                  halfDay,
                  item,
                  direction: "UP",
                })}
              >
                <i className="fa fa-arrow-up" aria-hidden="true" />
              </button>
            )}
            {!isLastItem && (
              <button
                type="button"
                className="reorder -down button button-outline"
                onClick={updatePosition({
                  halfDay,
                  item,
                  direction: "DOWN",
                })}
              >
                <i className="fa fa-arrow-down" aria-hidden="true" />
              </button>
            )}
          </div>
        )}
      </div>
    </Link>
  );
};

PickupItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    round_line_position: PropTypes.number.isRequired,
  }),
  halfDay: PropTypes.string.isRequired,
  updatePosition: PropTypes.func.isRequired,
  isReordering: PropTypes.bool.isRequired,
  isLastItem: PropTypes.bool.isRequired,
};

export default PickupItem;
