import React, { useState } from "react";
import PropTypes from "prop-types";

import logo from "./logo_fluidit.svg";

const Login = ({ code, setCode, hasError = false, loading = false }) => {
  const [newCode, setNewCode] = useState(code);
  const showError = hasError && code === newCode;

  return (
    <div className="app-login container">
      <h1 className="title _vertical-spacing">
        <img src={logo} className="logo" alt="Logo FluidIt" />
      </h1>
      <div className="row">
        <div className="column column-80 column-offset-10">
          <form
            onSubmit={(event) => {
              setCode(newCode);
              event.preventDefault();
            }}
          >
            <fieldset>
              <label htmlFor="roundIdentifier">Identifiant:</label>
              <input
                type="number"
                id="roundIdentifier"
                className={showError ? "_border-error" : null}
                value={newCode}
                autoComplete="off"
                onChange={(event) => setNewCode(event.target.value)}
              />
              {showError ? (
                <div className="input-hint _text-error _text-right">
                  Identifiant incorrect
                </div>
              ) : null}
              <div className="login-button _text-center">
                {loading ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                ) : (
                  <input
                    className="button-primary"
                    type="submit"
                    value="Se connecter"
                  />
                )}
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Login;
