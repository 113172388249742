import React from "react";
import PropTypes from "prop-types";

const PackageCount = ({ packageCount, setPackageCount }) => {
  return (
    <div className="package-count">
      <label className="package-count__label" htmlFor="nombre_colis">
        Nombre de colis
      </label>
      <input
        id="nombre_colis"
        name="nombre_colis"
        type="number"
        value={packageCount}
        onChange={(e) => setPackageCount(e.target.value)}
        min="0"
      />
    </div>
  );
};

PackageCount.propTypes = {
  packageCount: PropTypes.string.isRequired,
  setPackageCount: PropTypes.func.isRequired,
};

export default PackageCount;
