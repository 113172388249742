import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../Validation/ErrorMessage";
import {
  mustHaveOutletPlug,
  mustReplaceFiberSeal,
  mustControlSeal,
  isDisplayedPluggedByClient,
  isDisplayedGasCookingSecurityCheck,
  isDisplayedSecurity,
  isDisplayedFiberSealReplacement,
  isDisplayedSealingControl,
  isDisplayedSealingBrassSeal,
} from "../helpers/securityCheck";
import Radio from "components/Radio";
import QualitySheetFormSection from "./QualitySheetFormSection";

const SecurityCheck = ({ security, setSecurity, articleCategories }) => {
  const {
    changement_gicleurs,
    appareils_deux_arrivees,
    controle_etancheite,
    controle_branchement,
  } = security;

  return (
    isDisplayedSecurity(articleCategories) && (
      <div className="security-check">
        <div className="security-check__title">Sécurité</div>

        {isDisplayedPluggedByClient(articleCategories) && (
          <QualitySheetFormSection
            title="Branchement de l'appareil"
            rows={[
              <div key="Contrôle du branchement par le client">
                Contrôle du branchement
                <br /> de l&apos;appareil par le client
                <Radio
                  label="Oui"
                  checked={
                    controle_branchement.controle_branchement_par_client ===
                    "Oui"
                  }
                  onChange={() =>
                    setSecurity({
                      category: "controle_branchement",
                      inspectionPoint: "controle_branchement_par_client",
                      status: "Oui",
                    })
                  }
                />
                <Radio
                  label="Non"
                  checked={
                    controle_branchement.controle_branchement_par_client ===
                    "Non"
                  }
                  onChange={() =>
                    setSecurity({
                      category: "controle_branchement",
                      inspectionPoint: "controle_branchement_par_client",
                      status: "Non",
                    })
                  }
                />
              </div>,
            ]}
          />
        )}

        {isDisplayedGasCookingSecurityCheck(articleCategories) && (
          <>
            <QualitySheetFormSection
              title="Changement de gicleurs"
              rows={[
                <div key="Les gicleurs d’origine ont été remplacés par le technicien">
                  Les gicleurs d&apos;origine ont été
                  <br /> remplacés par le technicien
                  <Radio
                    label="Oui"
                    checked={
                      changement_gicleurs.gicleur_remplace_par_technicien ===
                      "Oui"
                    }
                    onChange={() =>
                      setSecurity({
                        category: "changement_gicleurs",
                        inspectionPoint: "gicleur_remplace_par_technicien",
                        status: "Oui",
                      })
                    }
                  />
                  <Radio
                    label="Non"
                    checked={
                      changement_gicleurs.gicleur_remplace_par_technicien ===
                      "Non"
                    }
                    onChange={() =>
                      setSecurity({
                        category: "changement_gicleurs",
                        inspectionPoint: "gicleur_remplace_par_technicien",
                        status: "Non",
                      })
                    }
                  />
                </div>,
              ]}
            />

            <div className="devices-with-two-inputs">
              <QualitySheetFormSection
                title="Pour les appareils disposant de deux arrivées"
                warning={
                  mustHaveOutletPlug(
                    appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee
                  ) && (
                    <ErrorMessage error="Attention, merci de vérifier l’installation de l’appareil, la mise en place du bouchon laiton sur la sortie non utilisée est OBLIGATOIRE." />
                  )
                }
                rows={[
                  <div key="Mise en place du bouchon laiton sur l’arrivée non utilisée">
                    Mise en place du bouchon laiton sur l’arrivée non utilisée
                    <div>
                      <Radio
                        label="Oui"
                        checked={
                          appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee ===
                          "Oui"
                        }
                        onChange={() =>
                          setSecurity({
                            category: "appareils_deux_arrivees",
                            inspectionPoint:
                              "bouchon_laiton_sur_arrivee_non_utilisee",
                            status: "Oui",
                          })
                        }
                      />
                      <Radio
                        label="Non"
                        checked={
                          appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee ===
                          "Non"
                        }
                        onChange={() =>
                          setSecurity({
                            category: "appareils_deux_arrivees",
                            inspectionPoint:
                              "bouchon_laiton_sur_arrivee_non_utilisee",
                            status: "Non",
                          })
                        }
                      />
                      <Radio
                        label="Bouchon déjà en place"
                        checked={
                          appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee ===
                          "Le bouchon était déjà en place"
                        }
                        onChange={() =>
                          setSecurity({
                            category: "appareils_deux_arrivees",
                            inspectionPoint:
                              "bouchon_laiton_sur_arrivee_non_utilisee",
                            status: "Le bouchon était déjà en place",
                          })
                        }
                      />
                      <Radio
                        label="Pas de deuxième arrivée"
                        checked={
                          appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee ===
                          "Pas de deuxième arrivée"
                        }
                        onChange={() =>
                          setSecurity({
                            category: "appareils_deux_arrivees",
                            inspectionPoint:
                              "bouchon_laiton_sur_arrivee_non_utilisee",
                            status: "Pas de deuxième arrivée",
                          })
                        }
                      />
                    </div>
                  </div>,
                ]}
              />
            </div>

            {isDisplayedFiberSealReplacement(
              appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee
            ) && (
              <div className="brass-plug-unused-inlet">
                <QualitySheetFormSection
                  title="Bouchon laiton sur arrivée non utilisée"
                  warning={
                    mustReplaceFiberSeal(
                      appareils_deux_arrivees.joint_fibre_remplace_si_bouchon_deplace
                    ) && (
                      <ErrorMessage error="Attention, le joint fibre doit être systématiquement remplacé par un neuf si le bouchon laiton a été déplacé d’une arrivée à l’autre." />
                    )
                  }
                  rows={[
                    <div key="Si le bouchon a été déplacé d’une arrivée à l’autre, le joint fibre a-t-il été remplacé ?">
                      Si le bouchon a été déplacé d’une arrivée à l’autre, le
                      joint fibre a-t-il été remplacés
                      <div>
                        <Radio
                          label="Oui"
                          checked={
                            appareils_deux_arrivees.joint_fibre_remplace_si_bouchon_deplace ===
                            "Oui"
                          }
                          onChange={() =>
                            setSecurity({
                              category: "appareils_deux_arrivees",
                              inspectionPoint:
                                "joint_fibre_remplace_si_bouchon_deplace",
                              status: "Oui",
                            })
                          }
                        />
                        <Radio
                          label="Non"
                          checked={
                            appareils_deux_arrivees.joint_fibre_remplace_si_bouchon_deplace ===
                            "Non"
                          }
                          onChange={() =>
                            setSecurity({
                              category: "appareils_deux_arrivees",
                              inspectionPoint:
                                "joint_fibre_remplace_si_bouchon_deplace",
                              status: "Non",
                            })
                          }
                        />
                        <Radio
                          label="Le bouchon était dans le sachet d’accessoires"
                          checked={
                            appareils_deux_arrivees.joint_fibre_remplace_si_bouchon_deplace ===
                            "Le bouchon était dans le sachet d’accessoires"
                          }
                          onChange={() =>
                            setSecurity({
                              category: "appareils_deux_arrivees",
                              inspectionPoint:
                                "joint_fibre_remplace_si_bouchon_deplace",
                              status:
                                "Le bouchon était dans le sachet d’accessoires",
                            })
                          }
                        />
                      </div>
                    </div>,
                  ]}
                />
              </div>
            )}

            {isDisplayedSealingControl(
              appareils_deux_arrivees.joint_fibre_remplace_si_bouchon_deplace,
              appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee
            ) && (
              <QualitySheetFormSection
                title="Contrôle de l’étanchéité"
                warning={
                  (mustControlSeal(controle_etancheite.tuyau_gaz) ||
                    mustControlSeal(controle_etancheite.bouchon_laiton)) && (
                    <ErrorMessage error="Attention, le contrôle d'étanchéité est OBLIGATOIRE" />
                  )
                }
                rows={[
                  <div key="Au niveau du tuyau de gaz">
                    Au niveau du tuyau de gaz
                    <Radio
                      label="Oui"
                      checked={controle_etancheite.tuyau_gaz === "Oui"}
                      onChange={() =>
                        setSecurity({
                          category: "controle_etancheite",
                          inspectionPoint: "tuyau_gaz",
                          status: "Oui",
                        })
                      }
                    />
                    <Radio
                      label="Non"
                      checked={controle_etancheite.tuyau_gaz === "Non"}
                      onChange={() =>
                        setSecurity({
                          category: "controle_etancheite",
                          inspectionPoint: "tuyau_gaz",
                          status: "Non",
                        })
                      }
                    />
                  </div>,
                  isDisplayedSealingBrassSeal(
                    appareils_deux_arrivees.bouchon_laiton_sur_arrivee_non_utilisee
                  ) && (
                    <div key="Au niveau du bouchon laiton">
                      Au niveau du bouchon laiton
                      <Radio
                        label="Oui"
                        checked={controle_etancheite.bouchon_laiton === "Oui"}
                        onChange={() =>
                          setSecurity({
                            category: "controle_etancheite",
                            inspectionPoint: "bouchon_laiton",
                            status: "Oui",
                          })
                        }
                      />
                      <Radio
                        label="Non"
                        checked={controle_etancheite.bouchon_laiton === "Non"}
                        onChange={() =>
                          setSecurity({
                            category: "controle_etancheite",
                            inspectionPoint: "bouchon_laiton",
                            status: "Non",
                          })
                        }
                      />
                    </div>
                  ),
                ]}
              />
            )}
          </>
        )}
      </div>
    )
  );
};

SecurityCheck.propTypes = {
  security: PropTypes.object.isRequired,
  setSecurity: PropTypes.func.isRequired,
  articleCategories: PropTypes.array.isRequired,
};

export default SecurityCheck;
