import { gql } from "@apollo/client";
import { ROADMAP_FRAGMENT } from "pages/Round/roadmapQuery";

export const START_ROUND = gql`
  ${ROADMAP_FRAGMENT}

  mutation startRound($code: String!, $halfDay: Halfdays!) {
    startRound(code: $code, halfDay: $halfDay) {
      success
      roadmap {
        ...RoadmapFragment
      }
    }
  }
`;

export const makeStartRoundOptions = (roadmap, halfDay) => ({
  variables: {
    code: roadmap.code,
    halfDay,
  },
  context: {
    offline: true,
  },
  optimisticResponse: {
    startRound: {
      __typename: "StartRoundMutationResponse",
      success: true,
      roadmap: {
        __typename: "Roadmap",
        id: roadmap.id,
        started: {
          ...roadmap.started,
          [halfDay]: true,
        },
        [halfDay]: roadmap[halfDay].map((item, index) => ({
          __typename: "RoadmapOrder",
          id: item.id,
          isDelayed: index <= 1,
        })),
      },
    },
  },
});
