import React from "react";
import PropTypes from "prop-types";

import DeliveryPayment from "./DeliveryPayment";
import PhotosInput from "components/PhotosInput";

const DeliveryPayments = ({
  clientPrice,
  storeSettle,
  payments,
  setPayments,
  hasRoundStarted,
  isDisabled,
  paymentPhotos,
  setPaymentPhotos,
}) => {
  const addPayment = (type) => {
    setPayments([
      ...payments,
      {
        id: Date.now(),
        _new: true,
        payment_method: null,
        paid_amount: null,
        type,
      },
    ]);
  };

  const updatePayment = (id, key, value) => {
    const paymentsClone = [...payments];
    const payment = paymentsClone.find((payment) => payment.id === id);
    if (key === "paid_amount") {
      payment[key] = Number(value);
    } else {
      payment[key] = value;
    }
    setPayments(paymentsClone);
  };

  const removePayment = (id) => {
    setPayments(payments.filter((payment) => payment.id !== id));
  };
  const handleAddClientPricePayment = (e) => {
    e.preventDefault();
    addPayment("client_price");
  };

  const handleAddStoreSettlePayment = (e) => {
    e.preventDefault();
    addPayment("store_settle");
  };

  const validPayments = payments.filter((payment) => payment.paid_amount > 0);
  const clientPricePayments = payments.filter(
    (payment) => payment.type === "client_price"
  );
  const storeSettlePayments = payments.filter(
    (payment) => payment.type === "store_settle"
  );

  const clientPricePaymentsElements = clientPricePayments.map(
    (deliveryPayment) => {
      return (
        <DeliveryPayment
          key={deliveryPayment.id}
          deliveryPayment={deliveryPayment}
          isDisabled={isDisabled}
          updatePayment={updatePayment}
          removePayment={removePayment}
        />
      );
    }
  );

  const storeSettlePaymentsElements = storeSettlePayments.map(
    (deliveryPayment) => {
      return (
        <DeliveryPayment
          key={deliveryPayment.id}
          deliveryPayment={deliveryPayment}
          isDisabled={isDisabled}
          updatePayment={updatePayment}
          removePayment={removePayment}
        />
      );
    }
  );

  return (
    <>
      <div className="delivery-payments">
        <div className="delivery-payment">
          <div className="delivery-payment__title">
            Montant à payer à la livraison
            <br /> par le client : {clientPrice}
            &nbsp;&euro;
          </div>
          {hasRoundStarted && (
            <div className="delivery-payment__payments">
              {clientPricePaymentsElements.length ? (
                <ul className="payment-list">{clientPricePaymentsElements}</ul>
              ) : null}
              <button
                type="button"
                className="add-payment-button"
                disabled={isDisabled}
                onClick={handleAddClientPricePayment}
              >
                Ajouter un paiement
              </button>
            </div>
          )}
        </div>

        <div className="delivery-payment">
          <div className="delivery-payment__title">
            Solde magasin à payer par le
            <br /> client: {storeSettle}
            &nbsp;&euro;
          </div>
          {hasRoundStarted && (
            <div className="delivery-payment__payments">
              {storeSettlePaymentsElements.length ? (
                <ul className="payment-list">{storeSettlePaymentsElements}</ul>
              ) : null}
              <button
                type="button"
                className="add-payment-button"
                disabled={isDisabled}
                onClick={handleAddStoreSettlePayment}
              >
                Ajouter un paiement
              </button>
            </div>
          )}
        </div>
      </div>
      {validPayments.length > 0 && (
        <PhotosInput
          disabled={isDisabled}
          photoList={paymentPhotos}
          setPhotoList={setPaymentPhotos}
          label={
            <div className="photo-input__label">
              Photos de l&apos;encaissement :
            </div>
          }
        />
      )}
    </>
  );
};

DeliveryPayments.propTypes = {
  clientPrice: PropTypes.number,
  storeSettle: PropTypes.number,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      paid_amount: PropTypes.number,
      type: PropTypes.oneOf(["client_price", "store_settle"]).isRequired,
    }).isRequired
  ).isRequired,
  setPayments: PropTypes.func.isRequired,
  hasRoundStarted: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  paymentPhotos: PropTypes.array.isRequired,
  setPaymentPhotos: PropTypes.func.isRequired,
};

export default DeliveryPayments;
