const getRemainingOrdersNumber = (halfdayOrderPickings) => {
  const halfdayOrderPickingsSuccessful = halfdayOrderPickings.reduce(
    (acc, halfdayOrderPicking) => {
      let hasAllPickupSuccessful = true;

      halfdayOrderPicking.order_articles.forEach((orderArticle) => {
        if (orderArticle.metadata.some((data) => !data.pickup_status)) {
          hasAllPickupSuccessful = false;
        }
      });

      if (hasAllPickupSuccessful) {
        return [...acc, halfdayOrderPicking];
      }

      return acc;
    },
    []
  );

  return halfdayOrderPickings.length - halfdayOrderPickingsSuccessful.length;
};

export default getRemainingOrdersNumber;
