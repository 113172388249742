import React from "react";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";

const BypassQualitySwitch = ({ bypass, toggleBypass, setBypassReason }) => {
  return (
    <fieldset className="bypass-quality-switch">
      <Checkbox
        checked={bypass.reponse}
        onChange={toggleBypass}
        label="Impossible de remplir le document"
      />

      {bypass.reponse && (
        <div className="bypass-quality-switch__reason">
          <label htmlFor="motifBypass">Motif :</label>
          <textarea
            id="motifContournement"
            type="text"
            name="bypass.motif"
            placeholder="Indiquer le motif"
            value={bypass.motif}
            onChange={(e) => setBypassReason(e.target.value)}
            cols={3}
          />
          <em>
            (Livraison annulée, Client âgé, Refus client, oubli tablette...)
          </em>
        </div>
      )}
    </fieldset>
  );
};

BypassQualitySwitch.propTypes = {
  bypass: PropTypes.object.isRequired,
  setBypassReason: PropTypes.func.isRequired,
  toggleBypass: PropTypes.func.isRequired,
};

export default BypassQualitySwitch;
