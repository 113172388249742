import React from "react";
import PropTypes from "prop-types";

const Radio = ({ disabled, label, checked, onChange, ...radioProps }) => {
  return (
    <label className="radio">
      <input
        {...radioProps}
        checked={checked}
        onChange={(ev) => onChange(ev.target.checked)}
        type="radio"
      />
      {label}
    </label>
  );
};

Radio.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Radio;
