import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const DeliverySubmit = ({
  isDisabled,
  isValidated,
  isLoading,
  isOnline,
  handleSubmit,
}) => {
  return (
    <div className="delivery-submit">
      {!isOnline && isLoading ? (
        <>
          <p>
            Vous êtes hors-ligne, la validation de la livraison sera effectuée
            quand le réseau sera disponible
          </p>
          <div className="_text-center">
            <Link to="/" className="submitButton">
              Retourner sur la feuille de route
            </Link>
          </div>
        </>
      ) : (
        <button
          type="submit"
          className="delivery-submit-button"
          disabled={isDisabled || isLoading || isValidated}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <i className="fa fa-spinner fa-spin" aria-hidden="true" />
          ) : isValidated ? (
            "Livraison terminée"
          ) : (
            "Valider"
          )}
        </button>
      )}
    </div>
  );
};
DeliverySubmit.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isValidated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default DeliverySubmit;
