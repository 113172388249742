import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { format } from "utils/date";

const Time = ({ timestamp, ...props }) => {
  const formatted = format(new Date(timestamp), "yyyy/MM/dd HH:mm:ss");
  return (
    <time dateTime={formatted} {...props}>
      {formatted}
    </time>
  );
};

Time.propTypes = {
  timestamp: PropTypes.number.isRequired,
};

const PhotosInput = ({ disabled, label, photoList, setPhotoList }) => {
  const [isSnapping, setIsSnapping] = useState(false);
  const [isCameraLoading, setIsCameraLoading] = useState(false);

  useEffect(() => {
    if (
      isSnapping &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      const device = document.getElementById("camera-device");
      const camera = document.getElementById("camera");

      const gotDevices = (deviceInfos) => {
        device.innerHTML = "";
        for (let i = 0; i !== deviceInfos.length; ++i) {
          let deviceInfo = deviceInfos[i];
          let option = document.createElement("option");
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === "videoinput") {
            option.text = deviceInfo.label || "camera " + (device.length + 1);
            if (deviceInfo.label.includes("facing back")) {
              option.selected = true;
            }
            device.appendChild(option);
          }
        }
      };

      const getStream = () => {
        if (camera.srcObject) {
          camera.srcObject.getTracks().forEach(function (track) {
            track.stop();
          });
          camera.srcObject = null;
        }

        const constraints = {
          video: { deviceId: { exact: device.value } },
        };

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            if (camera) {
              camera.srcObject = stream;
              camera.play();
            }
          })
          .catch((error) => {
            alert(error + "first catch");
            // It will fail if user has not yet allowed the camera to be used
            const constraints = {
              video: true,
            };

            navigator.mediaDevices
              .getUserMedia(constraints)
              .then((stream) => {
                if (camera) {
                  camera.srcObject = stream;
                  camera.play();
                }
              })
              .catch((error) => alert(error + "second catch"));
          });
      };

      device.onchange = getStream;

      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .then(getStream)
        .catch((error) => {
          alert(error);
          console.error("Error: ", error);
        });
    }
    //TODO fix my dependencies
    //eslint-disable-next-line
  }, [photoList, isSnapping]);

  const realPhotoLength = photoList?.filter((photo) => !photo.remove).length;

  const isCameraPlaying = () => {
    const camera = document.getElementById("camera");
    return camera && camera.readyState >= 2 && !camera.paused && !camera.ended;
  };

  const cameraDidLoad = () => {
    if (!isCameraPlaying()) {
      document.getElementById("camera").play();
    }

    if (isCameraLoading) {
      setIsCameraLoading(false);
    }
  };

  const stopPicture = () => {
    const camera = document.getElementById("camera");
    if (camera.srcObject) {
      camera.srcObject.getTracks().forEach((track) => track.stop());
    }

    if (camera && camera.stop) {
      camera.stop();
    }

    setIsSnapping(false);
  };

  const takePicture = (event) => {
    event.preventDefault();
    setIsCameraLoading(true);

    const camera = document.getElementById("camera");
    const ratio = camera.videoWidth / camera.videoHeight;

    const height = Math.min(1080, camera.videoHeight);
    const width = height * ratio;

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(camera, 0, 0, width, height);

    setPhotoList([
      ...photoList,
      {
        id: Date.now(),
        src: canvas.toDataURL("image/jpeg", 0.75),
        new: true,
      },
    ]);
    stopPicture();
  };

  const cancelPicture = (event) => {
    event.preventDefault();
    setIsCameraLoading(true);
    stopPicture();
  };

  const addPhoto = () => {
    setIsSnapping(true);
    setIsCameraLoading(!isCameraPlaying());
  };

  const removePaymentPhoto = (photoId) => {
    setPhotoList(
      photoList.map((photo) => ({
        ...photo,
        remove: photo.id === photoId || photo.remove,
      }))
    );
  };

  const unremovePaymentPhoto = (photoId) => {
    setPhotoList(
      photoList.map((photo) => ({
        ...photo,
        remove: photo.id === photoId ? false : !!photo.remove,
      }))
    );
  };

  const hasPhotos = photoList?.length;

  if (disabled && !hasPhotos) {
    return <section className="photo-input row">{label}</section>;
  }

  return (
    <section className="photo-input">
      {label}
      <section className="photos-section">
        <h3>
          {Boolean(hasPhotos) && <small>( {realPhotoLength} / 5 )</small>}
        </h3>
        <ul className="photos-list">
          {photoList.map((photo, index) => (
            <li
              key={photo.id}
              className={
                "photo-item" +
                (photo.remove ? " -remove" : "") +
                (photo.removed ? " -removed" : "")
              }
            >
              <div className="photo-holder">
                {photo.removed ? (
                  "photo supprimée"
                ) : (
                  <img src={photo.src} alt={`${index}`} className="photo" />
                )}
              </div>
              {!disabled && photo.new && !photo.remove ? (
                <button
                  className="button delete"
                  onClick={(event) => {
                    event.preventDefault();
                    removePaymentPhoto(photo.id);
                  }}
                >
                  Supprimer la photo
                </button>
              ) : null}
              {!disabled && photo.new && photo.remove ? (
                <button
                  className="button undelete"
                  onClick={(event) => {
                    event.preventDefault();
                    if (realPhotoLength >= 5) {
                      alert(
                        "Vous avez déjà 5 photos sélectionnée. Supprimez d'abord une photo."
                      );
                    } else {
                      unremovePaymentPhoto(photo.id);
                    }
                  }}
                >
                  Annuler la suppression
                </button>
              ) : null}
              <Time timestamp={Number(photo.id)} className="time" />
            </li>
          ))}
          {disabled || realPhotoLength >= 5 ? null : (
            <li className="photo-item">
              <button
                className="photo addPhotoButton"
                onClick={(event) => {
                  event.preventDefault();
                  addPhoto();
                }}
              >
                <h2>+</h2>
                Ajouter une photo
              </button>
            </li>
          )}
        </ul>
        {isSnapping ? (
          <div className="photo-snapper">
            {isCameraLoading ? (
              <h3 className="loading">Chargement...</h3>
            ) : null}
            <select
              className={classnames("cameraDevice", {
                _none: isCameraLoading,
              })}
              id="camera-device"
            />
            <video
              className="cameraView"
              id="camera"
              onLoadedData={cameraDidLoad}
            />
            {isCameraLoading ? null : (
              <button className="takePictureButton" onClick={takePicture}>
                Prendre la photo
              </button>
            )}
            {isCameraLoading ? null : (
              <button className="cancelButton" onClick={cancelPicture}>
                Annuler
              </button>
            )}
          </div>
        ) : null}
      </section>
    </section>
  );
};

PhotosInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  photoList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      src: PropTypes.string.isRequired,
      new: PropTypes.bool,
      remove: PropTypes.bool,
    }).isRequired
  ).isRequired,
  setPhotoList: PropTypes.func.isRequired,
};

export default PhotosInput;
