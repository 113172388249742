import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { cancelScreenTimeout } from "utils/config";

const useInterval = (handler, interval) => {
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    const id = setInterval(handler, interval);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [handler, interval]);

  return () => clearInterval(intervalId);
};

const CancelScreenModal = ({ cancelCallback }) => {
  const [percentage, setPercentage] = useState(0);

  const tick = useCallback(
    () => setPercentage((percentage) => percentage + 1),
    []
  );

  const interval = cancelScreenTimeout / 100;
  const clearInterval = useInterval(tick, interval);

  useEffect(() => {
    if (percentage >= 100) {
      clearInterval();
    }
  }, [percentage, clearInterval]);

  return (
    <div className="cancel-screen">
      <div className="cancel-screen__container">
        <CircularProgressbar
          value={percentage}
          strokeWidth={4}
          styles={buildStyles({
            pathColor: "#20274A",
            pathTransitionDuration: 0,
          })}
        />

        <button
          className="cancel-screen__button"
          onClick={() => {
            clearInterval();
            cancelCallback();
          }}
        >
          Annuler
        </button>
      </div>
    </div>
  );
};

CancelScreenModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
};

export default CancelScreenModal;
