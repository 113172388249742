import React from "react";
import PropTypes from "prop-types";
import { parse, format } from "utils/date";

const RoadmapHeader = ({
  roadmap,
  isReordering,
  setIsReordering,
  reloadRoadmap,
  reloading,
}) => {
  const date = parse(roadmap.date);

  return (
    <header className="round-header">
      <div className="row">
        <div className="column">
          <h1 className="title">Feuille de route</h1>

          <div className="action">
            <button
              className="button button-outline"
              title="Réorganiser la tournée"
              onClick={() => setIsReordering(!isReordering)}
            >
              <i className="fa fa-sort" />
            </button>
            {reloading ? (
              <div className="button button-outline">
                <i className="fa fa-spinner fa-spin" aria-hidden="true" />
              </div>
            ) : (
              <button
                className="button button-outline"
                title="Rafraîchir"
                onClick={() => reloadRoadmap()}
              >
                <i className="fas fa-sync" />
              </button>
            )}
          </div>

          <div className="info">
            <div>
              <span className="label">Camion</span> {roadmap.truck_name}
            </div>
            <div>
              <span className="label">Equipe</span> : {roadmap.crewmates}
            </div>
            <div>{format(date, "EEEE dd MMMM yyyy")}</div>
          </div>
        </div>
      </div>
    </header>
  );
};

RoadmapHeader.propTypes = {
  reloading: PropTypes.bool.isRequired,
  reloadRoadmap: PropTypes.func.isRequired,
  roadmap: PropTypes.shape({
    date: PropTypes.string.isRequired,
    truck_name: PropTypes.string.isRequired,
    crewmates: PropTypes.string,
  }).isRequired,
  isReordering: PropTypes.bool,
  setIsReordering: PropTypes.func.isRequired,
};

export default RoadmapHeader;
