import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeliveryPayments from "components/Delivery/Payments";
import DeliveryArticle from "components/Delivery/DeliveryArticle";
import SAV from "components/Delivery/SAV";

const Submit = ({
  disabled,
  handleSubmit,
  isLoading,
  isOnline,
  isDeliveryFailed,
}) => {
  return !isOnline && isLoading ? (
    <>
      <p>
        Vous êtes hors-ligne, la validation de la livraison sera effectuée quand
        le réseau sera disponible
      </p>
      <div className="_text-center">
        <Link to="/" className="submitButton">
          Retourner sur la feuille de route
        </Link>
      </div>
    </>
  ) : (
    <button
      type="button"
      className="delivery-button-submit"
      disabled={disabled}
      onClick={handleSubmit}
    >
      {isLoading ? (
        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      ) : (
        isDeliveryFailed && "Valider"
      )}
    </button>
  );
};

Submit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDeliveryFailed: PropTypes.bool.isRequired,
};

const DeliveryForm = ({
  delivery,
  display: { isLoading, isOnline, isDeliveryFailed },
  formState: {
    payments,
    paymentPhotos,
    comment,
    SAVPhotos,
    SAVComment,
    orderArticles,
  },
  validations: {
    hasValidPaymentPhotos,
    hasValidSAVPhotos,
    isValidated,
    hasRoundStarted,
  },
  actions: {
    setPayments,
    setPaymentPhotos,
    setComment,
    setShowQualitySheet,
    setSAVPhotos,
    setSAVComment,
    qualitySheetActions,
    setOrderArticles,
    setOrderArticleDeliveryStatus,
    setOrderArticleFailureReason,
    setOrderArticleMetadataDeliveryStatus,
    setOrderArticleMetadataFailureReason,
  },
  handleSubmit,
  someOrderArticlesHaveNoFailureReason,
  someOrderArticlesAreNotDelivered,
}) => {
  const isDeliveryFailedSubmitDisabled = !hasValidSAVPhotos;

  const isDeliverySubmitDisabled = React.useMemo(() => {
    /* 
        If we are not in the global delivery failed case
        (!isDeliveryFailed &&

          And at least one order article is failed (not delivered) but has no failure raison
          Object.keys(orderArticles).some((orderArticleKey) =>
          orderArticles[orderArticleKey].metadata.some((metadatum) => {
           
            return !metadatum.is_delivered && !metadatum.failure_reason;
          })
        ))

        Or at least one mandatory photo
      */
    if (
      !hasValidSAVPhotos ||
      (someOrderArticlesAreNotDelivered &&
        someOrderArticlesHaveNoFailureReason) ||
      (!isDeliveryFailed && someOrderArticlesHaveNoFailureReason)
    ) {
      return true;
    }

    return false;
  }, [
    isDeliveryFailed,
    hasValidSAVPhotos,
    someOrderArticlesAreNotDelivered,
    someOrderArticlesHaveNoFailureReason,
  ]);

  const isStoreReturn = Boolean(Number(delivery.order.store_return));
  const hasFinancingFile = Boolean(
    Number(delivery.order.prices.financing_file)
  );

  return (
    <form className="delivery-form" id="deliveryForm">
      {!isStoreReturn ? (
        <>
          {hasFinancingFile && (
            <div className="funding-warning">
              ATTENTION: Faire signer le dossier de financement
            </div>
          )}

          <DeliveryPayments
            clientPrice={delivery.order.prices.client_price}
            storeSettle={delivery.order.prices.store_settle}
            payments={payments}
            setPayments={setPayments}
            hasRoundStarted={hasRoundStarted}
            isDisabled={isValidated}
            paymentPhotos={paymentPhotos}
            setPaymentPhotos={setPaymentPhotos}
          />
          {hasRoundStarted && (
            <div className="payment-comment">
              <div className="payment-comment__label">
                Commentaire paiement :{" "}
              </div>
              <textarea
                className="payment-comment__textarea"
                id="comment"
                type="text"
                name="comment"
                rows={3}
                disabled={!hasRoundStarted || isValidated}
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </div>
          )}
        </>
      ) : null}
      <div className="delivery-form-articles">
        <div className="delivery-form-articles__title-container">
          <div className="delivery-form-articles__title">Articles :</div>
          {someOrderArticlesHaveNoFailureReason && !isDeliveryFailed && (
            <div className="delivery-form-articles__warning">
              <i className="fa fa-exclamation-triangle"></i> Veuillez confirmer
              les articles livrés ou indiquer une raison d&apos;échec
            </div>
          )}
        </div>
        <div className="delivery-form-articles__separator" />
        <div className="article-list">
          {Object.keys(orderArticles).map((orderArticleKey) => (
            <DeliveryArticle
              key={orderArticleKey}
              isValidated={isValidated}
              orderArticle={orderArticles[orderArticleKey]}
              setOrderArticles={setOrderArticles}
              setOrderArticleDeliveryStatus={setOrderArticleDeliveryStatus}
              setOrderArticleFailureReason={setOrderArticleFailureReason}
              setOrderArticleMetadataDeliveryStatus={
                setOrderArticleMetadataDeliveryStatus
              }
              setOrderArticleMetadataFailureReason={
                setOrderArticleMetadataFailureReason
              }
              isDeliveryFailed={isDeliveryFailed}
            />
          ))}
        </div>
      </div>

      <div className="delivery-form__observations">
        <span>Observations :</span>
        <span>{delivery.order.client_observation}</span>
      </div>
      {hasRoundStarted && (
        <SAV
          disabled={isValidated}
          SAVPhotos={SAVPhotos}
          setSAVComment={setSAVComment}
          setSAVPhotos={setSAVPhotos}
          setIsCompliant={qualitySheetActions.setIsCompliant}
          SAVComment={SAVComment}
        />
      )}
      {!hasValidPaymentPhotos && (
        <div className="delivery-form__error">
          <i className="fa fa-exclamation-triangle"></i>
          Il est obligatoire de faire une photo d&apos;encaissement lors
          d&apos;un paiement par le client
        </div>
      )}
      {!isValidated &&
        hasRoundStarted &&
        (isDeliveryFailed ? (
          <div>
            {!hasValidSAVPhotos && (
              <div className="delivery-form__error">
                <i className="fa fa-exclamation-triangle"></i>
                Photo obligatoire
              </div>
            )}

            <Submit
              handleSubmit={handleSubmit}
              disabled={isDeliveryFailedSubmitDisabled}
              isOnline={isOnline}
              isLoading={isLoading}
              isDeliveryFailed={isDeliveryFailed}
            />
          </div>
        ) : (
          <>
            {!hasValidSAVPhotos && (
              <div className="delivery-form__error">
                <i className="fa fa-exclamation-triangle"></i>
                Photo obligatoire
              </div>
            )}
            <button
              disabled={isDeliverySubmitDisabled}
              type="button"
              className="delivery-submit-button"
              onClick={() => {
                setShowQualitySheet(true);
              }}
            >
              Valider
            </button>
          </>
        ))}
    </form>
  );
};

DeliveryForm.propTypes = {
  delivery: PropTypes.shape({
    order: PropTypes.shape({
      prices: PropTypes.shape({
        client_price: PropTypes.number,
        store_settle: PropTypes.number,
        financing_file: PropTypes.bool,
      }).isRequired,
      store_return: PropTypes.bool.isRequired,
      client_observation: PropTypes.string,
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          article: PropTypes.shape({ id: PropTypes.string.isRequired })
            .isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  display: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool.isRequired,
    isDeliveryFailed: PropTypes.bool.isRequired,
  }),
  formState: PropTypes.shape({
    payments: PropTypes.array.isRequired,
    paymentPhotos: PropTypes.array.isRequired,
    comment: PropTypes.string.isRequired,
    SAVPhotos: PropTypes.array.isRequired,
    SAVComment: PropTypes.string.isRequired,
    orderArticles: PropTypes.object.isRequired,
  }),
  validations: PropTypes.shape({
    hasValidPaymentPhotos: PropTypes.bool.isRequired,
    hasValidSAVPhotos: PropTypes.bool.isRequired,
    isValidated: PropTypes.bool.isRequired,
    hasRoundStarted: PropTypes.bool.isRequired,
  }),
  actions: PropTypes.shape({
    setPayments: PropTypes.func.isRequired,
    setPaymentPhotos: PropTypes.func.isRequired,
    setComment: PropTypes.func.isRequired,
    setShowQualitySheet: PropTypes.func.isRequired,
    setSAVPhotos: PropTypes.func.isRequired,
    setSAVComment: PropTypes.func.isRequired,
    qualitySheetActions: PropTypes.shape({
      setIsCompliant: PropTypes.func.isRequired,
    }),
    setOrderArticles: PropTypes.func.isRequired,
    setOrderArticleDeliveryStatus: PropTypes.func.isRequired,
    setOrderArticleFailureReason: PropTypes.func.isRequired,
    setOrderArticleMetadataDeliveryStatus: PropTypes.func.isRequired,
    setOrderArticleMetadataFailureReason: PropTypes.func.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  someOrderArticlesHaveNoFailureReason: PropTypes.bool,
  someOrderArticlesAreNotDelivered: PropTypes.bool,
};

export default DeliveryForm;
