import { format as fnsFormat, parse as fnsParse } from "date-fns";
import { fr } from "date-fns/locale";

export const format = (date, format) => fnsFormat(date, format, { locale: fr });

export const utcNow = () =>
  fnsFormat(
    new Date(new Date().toISOString().slice(0, -1)),
    "yyyy-MM-dd HH:mm:ss"
  );

export const parse = (date, format = "yyyy-MM-dd") =>
  fnsParse(date, format, new Date());
