import React from "react";
import PropTypes from "prop-types";
import SignaturePad from "../ReactSignatureCanvas/ReactSignatureCanvas";

const Signature = ({ setSignature }) => {
  let signaturePad = {};

  const clear = () => {
    signaturePad.clear();
    setSignature({});
  };

  const trim = () => {
    setSignature(signaturePad.getTrimmedCanvas().toDataURL());
  };

  const canvasSizes = {
    width: window.innerWidth >= 724 ? 724 : window.innerWidth * 0.75,
    height: 328,
  };

  return (
    <div className="signature">
      <div className="signature__title">Signature</div>
      <SignaturePad
        canvasProps={{
          width: canvasSizes.width,
          height: canvasSizes.height,
          className: "sigPad",
        }}
        backgroundColor="white"
        clearOnResize={false}
        ref={(element) => {
          signaturePad = element;
        }}
        onEnd={trim}
      />

      <button className="signature__action" type="button" onClick={clear}>
        Effacer
      </button>
    </div>
  );
};

Signature.propTypes = {
  setSignature: PropTypes.func.isRequired,
};

export default Signature;
