import React from "react";
import PropTypes from "prop-types";

const Observations = ({ order }) => {
  const observation_internal =
    ("" + order.observations.internal).replace(/^null$/, "").trim() || null;

  const observation_client =
    ("" + order.observations.client).replace(/^null$/, "").trim() || null;

  const observation_title =
    observation_client && observation_internal
      ? "Observations"
      : observation_internal
      ? "Observations interne"
      : observation_client
      ? "Observation client"
      : null;

  return observation_title ? (
    <div className="row observation">
      <div className="column">
        <span className="label">{observation_title}</span> :{" "}
        {observation_internal && observation_client ? (
          <div>
            <section>
              Interne&nbsp;:&nbsp;
              <q>{observation_internal}</q>
            </section>
            <section>
              Client&nbsp;:&nbsp;
              <q>{observation_client}</q>
            </section>
          </div>
        ) : observation_client ? (
          <q>{observation_client}</q>
        ) : observation_internal ? (
          <q>{observation_internal}</q>
        ) : null}
      </div>
    </div>
  ) : null;
};

Observations.propTypes = {
  order: PropTypes.shape({
    observations: PropTypes.shape({
      internal: PropTypes.string.isRequired,
      client: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Observations;
