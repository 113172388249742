import { useContext, createContext } from "react";

const AppTitleContext = createContext({
  title: null,
  setAppTitle: () => {},
});

const useAppTitle = () => {
  const { title, setTitle } = useContext(AppTitleContext);

  return {
    title,
    setTitle,
  };
};

export { AppTitleContext };

export default useAppTitle;
