import React from "react";
import PropTypes from "prop-types";
import { BrowserMultiFormatReader, DecodeHintType } from "@zxing/library";
import { turnOnTorchLight, turnOffTorchLight } from "utils/torchlight";
import getVideoStream from "utils/getVideoStream";
import { PICKUP_INCIDENTS } from "pages/Picking/usePicking";
import classNames from "classnames";

const TIME_BETWEEN_SCAN_MILLIS = 300;
const bip = new Audio("/sounds/bip.mp3");

const getCodeReader = () => {
  const hints = new Map();

  hints.set(DecodeHintType.TRY_HARDER);

  return new BrowserMultiFormatReader(hints, TIME_BETWEEN_SCAN_MILLIS);
};

const codeReader = getCodeReader();

const useBarcodeScanner = ({ videoRef, target, onSuccessfulScan }) => {
  const [stream, setStream] = React.useState(null);
  const [isScanning, setIsScanning] = React.useState(false);
  const [failedToMatchTarget, setFailedToMatchTarget] = React.useState(false);
  const [result, setResult] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (stream?.active) {
      turnOnTorchLight(stream);
    }
  }, [stream]);

  /* This function is used to stop the video flux */
  const reset = React.useCallback(() => {
    codeReader.reset();
  }, []);

  const decodeOnceFromStream = React.useCallback(
    (stream) => {
      setIsScanning(true);

      codeReader
        .decodeOnceFromStream(stream, videoRef.current)
        .then(async (result) => {
          /* ¯\_(ツ)_/¯ */
          bip.play();

          /* Force torch light off to prevent device to let it on even if stream is not present */
          await turnOffTorchLight(stream);

          setIsScanning(false);

          const code = result.text;

          /** We successfully matched the target ! */
          if (code === target) {
            onSuccessfulScan(code);

            return;
          }

          /** Match failed, we will prompt the user with a retry button */
          setFailedToMatchTarget(true);
          setResult(code);
        })
        .catch(async (e) => {
          setIsScanning(false);

          /* Force torch light off to prevent device to let it on even if stream is not present */
          await turnOffTorchLight(stream);

          /* For the moment we reset the codeReader to retry automatically */
          reset();
        });
    },
    [onSuccessfulScan, reset, target, videoRef]
  );

  const scanTarget = React.useCallback(
    async (target) => {
      /* Already scanning, just ignore the call */
      if (isScanning) {
        return;
      }
      const _stream = await getVideoStream({
        video: { facingMode: "environment" },
      });

      /* Make stream available to others */
      setStream(_stream);

      decodeOnceFromStream(_stream, target);
    },
    [isScanning, setStream, decodeOnceFromStream]
  );

  const retry = (target) => {
    setFailedToMatchTarget(false);
    setResult(null);
    setError(null);
    scanTarget(target);
  };

  const getScanStatus = () => {
    if (failedToMatchTarget && result) {
      return `Echec, le code barre attendu était '${target}' mais vous avez scanné '${result}'`;
    }
    if (error) {
      return error;
    }

    if (isScanning || (!result && !error)) {
      return "Scan en cours...";
    }

    return "Scan réussi !";
  };

  return {
    scanTarget,
    retry,
    reset,
    getScanStatus,
    failedToMatchTarget,
    stream,
  };
};

const BarcodeScanner = ({
  open,
  setOpen,
  target = "",
  onSuccessfulScan,
  onAbortScan,
}) => {
  const videoRef = React.useRef();

  const {
    scanTarget,
    retry,
    reset,
    getScanStatus,
    failedToMatchTarget,
  } = useBarcodeScanner({
    videoRef,
    target,
    onSuccessfulScan,
  });

  const handleClose = () => {
    setOpen(false);
    /* This stop the camera stream to prevent intensive battery discharge */
    reset();
  };

  const handleAbortScan = (success, reason) => {
    onAbortScan(success, reason);
    reset();
  };

  React.useEffect(() => {
    if (open && target) {
      scanTarget(target);
    }
  }, [open, target, scanTarget]);

  if (!open) {
    return null;
  }

  return (
    <div
      className={classNames("barcode-scanner", {
        "barcode-scanner--visible": open,
      })}
    >
      <div className="scan-overlay">
        <div className="scan-overlay__close">
          <button className="scan-overlay__close-button" onClick={handleClose}>
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
        <div className="scan-overlay__target">
          <b>Code barre à scanner</b> : {target}
        </div>
        <div className="scan-overlay__video-container">
          <video ref={videoRef} />
        </div>

        <div className="scan-overlay__status">
          <b>Status</b> : {getScanStatus()}
        </div>
        {failedToMatchTarget && (
          <button
            className="scan-overlay__retry-button"
            onClick={() => retry(target)}
          >
            Réessayer
          </button>
        )}

        <div className="scan-overlay__actions">
          <button
            onClick={() => handleAbortScan(true, PICKUP_INCIDENTS.FORCED.value)}
          >
            Forcer
          </button>
          <button
            onClick={() =>
              handleAbortScan(true, PICKUP_INCIDENTS.EXHIBITION_PRODUCT.value)
            }
          >
            Produit d&apos;exposition
          </button>
          <button
            onClick={() =>
              handleAbortScan(
                false,
                PICKUP_INCIDENTS.PRODUCT_OR_EAN_DOES_NOT_MATCH.value
              )
            }
          >
            Mauvais produit
          </button>
        </div>
      </div>
    </div>
  );
};

BarcodeScanner.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  target: PropTypes.string,
  onSuccessfulScan: PropTypes.func.isRequired,
  onAbortScan: PropTypes.func.isRequired,
};

export default BarcodeScanner;
