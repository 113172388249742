import React from "react";
import PropTypes from "prop-types";
import QualitySheetFormSection from "./QualitySheetFormSection";
import Radio from "components/Radio";

const categories = {
  respect_interieur: "Respect de votre intérieur",
  respect_rdv: "Respect du rendez-vous",
  montage: "Montage",
};

const opinions = ["Oui", "Non"];

const SatisfactionTable = ({ satisfaction, setSatisfaction }) => {
  return (
    <QualitySheetFormSection
      title="Livraison"
      rows={Object.entries(categories).map(([categoryKey, category]) => {
        const categorySatisfaction = satisfaction[categoryKey];

        return (
          <div key={category}>
            {category}
            {opinions.map((opinion) => (
              <Radio
                key={`${category}-${opinion}`}
                label={opinion}
                checked={categorySatisfaction === opinion}
                onChange={() => setSatisfaction(categoryKey, opinion)}
              />
            ))}
          </div>
        );
      })}
    />
  );
};

SatisfactionTable.propTypes = {
  satisfaction: PropTypes.object.isRequired,
  setSatisfaction: PropTypes.func.isRequired,
};

export default SatisfactionTable;
