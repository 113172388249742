import { Store, del, set, get } from "idb-keyval";

const apolloStore = new Store("apollo-cache", "apollo-store");
const versionStore = new Store("version", "version-store");

const APP_VERSION_KEY = "app-version";

const setVersion = async (version) => {
  return await set(APP_VERSION_KEY, version, versionStore);
};

const getVersion = async () => {
  return await get(APP_VERSION_KEY, versionStore);
};

const getItem = (key) => {
  return get(key, apolloStore);
};

const removeItem = (key) => {
  return del(key, apolloStore);
};

const setItem = (key, value) => {
  return set(key, value, apolloStore);
};

const ApolloStore = { getItem, removeItem, setItem };

export default ApolloStore;
export { setVersion, getVersion };
