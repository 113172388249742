import React from "react";
import PropTypes from "prop-types";
import { parse, format } from "utils/date";
import ClientInformations from "components/Delivery/ClientInformations";

const formatDateTime = (date, fromTime, toTime) => {
  const formattedDate = format(parse(date), "EEEE dd/MM/yyyy");
  const formattedFromTime = format(parse(fromTime, "HH:mm:ss"), "HH:mm");
  const formattedToTime = format(parse(toTime, "HH:mm:ss"), "HH:mm");

  return `${formattedDate} entre ${formattedFromTime} et ${formattedToTime}`;
};

const DeliveryInformation = ({ delivery }) => {
  const numberOfPackages = delivery.order.articles.reduce(
    (acc, { number_of_packages }) => acc + number_of_packages,
    0
  );
  return (
    <div className="delivery-informations">
      <div className="delivery-informations__contractor">
        <div className="delivery-informations__store">
          <b>Magasin : </b>
          {delivery.order.contractor.identity.code}
        </div>
        <div className="delivery-informations__reference">
          <b>Référence : </b>
          {delivery.order.contractor.identity.code}
        </div>
      </div>

      <div className="delivery-informations__delivery-date">
        Livraison le{" "}
        {formatDateTime(
          delivery.order.delivery.date,
          delivery.order.delivery.from_time,
          delivery.order.delivery.to_time
        )}
      </div>

      <ClientInformations
        {...{
          [+delivery.order.store_return ? "magasin" : "client"]: delivery.order
            .client,
        }}
        numberOfPackages={numberOfPackages}
      />
    </div>
  );
};

DeliveryInformation.propTypes = {
  delivery: PropTypes.shape({
    order: PropTypes.shape({
      articles: PropTypes.array.isRequired,
      contractor: PropTypes.shape({
        identity: PropTypes.shape({ code: PropTypes.string.isRequired })
          .isRequired,
      }).isRequired,
      reference: PropTypes.string.isRequired,
      store_return: PropTypes.bool.isRequired,
      client: PropTypes.object.isRequired,
      delivery: PropTypes.shape({
        date: PropTypes.string.isRequired,
        from_time: PropTypes.string.isRequired,
        to_time: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default DeliveryInformation;
