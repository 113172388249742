import { useState } from "react";
import useAuth from "./useAuth";

const useCode = () => {
  const { user } = useAuth();
  const [code, setCode] = useState(user?.code || "");

  return {
    code,
    setCode: (code) => {
      setCode(code);
    },
  };
};

export default useCode;
