import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const DeliveryPayment = ({
  deliveryPayment,
  isDisabled,
  updatePayment,
  removePayment,
}) => {
  const handleUpdate = (e) => {
    e.preventDefault();
    updatePayment(deliveryPayment.id, e.target.name, e.target.value);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    removePayment(deliveryPayment.id);
  };

  return (
    <div className="payment-item">
      <div className="payment-item__method">
        <label htmlFor={`payment_method_${deliveryPayment.id}`}>
          Mode de paiement
        </label>
        <select
          name="payment_method"
          id={`payment_method_${deliveryPayment.id}`}
          disabled={isDisabled}
          value={deliveryPayment.payment_method || ""}
          onChange={handleUpdate}
        >
          <option value="" />
          <option value="cash">Espèces</option>
          <option value="check">Chèque</option>
          <option value="other">Autre</option>
        </select>
      </div>

      <div className="payment-item__amount">
        <label htmlFor={`paid_amount_${deliveryPayment.id}`}>Montant (€)</label>
        <NumberFormat
          name="paid_amount"
          id={`paid_amount_${deliveryPayment.id}`}
          decimalScale={2}
          disabled={isDisabled}
          value={deliveryPayment.paid_amount || ""}
          onChange={handleUpdate}
        />
      </div>

      <div className="payment-item__action">
        <button
          type="button"
          className="button"
          title="Supprimer le paiement"
          disabled={isDisabled}
          onClick={handleRemove}
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

DeliveryPayment.propTypes = {
  deliveryPayment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    paid_amount: PropTypes.number,
    payment_method: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  updatePayment: PropTypes.func.isRequired,
  removePayment: PropTypes.func.isRequired,
};

export default DeliveryPayment;
