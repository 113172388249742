import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Disclaimer = ({
  disabled,
  disclaimer,
  toggleDisclaimer,
  setDisclaimerUserInput,
}) => {
  return (
    <div
      className={classNames("disclaimer-accordion", {
        "disclaimer-accordion--open": disclaimer.reponse,
      })}
    >
      <div className="disclaimer-accordion-header" onClick={toggleDisclaimer}>
        <div className="disclaimer-accordion-header__title">
          Décharge de responsabilité
        </div>
        <div className="disclaimer-accordion-header__action">
          {disclaimer.reponse ? (
            <i className="fas fa-chevron-down fa-2x"></i>
          ) : (
            <i className="fas fa-chevron-right fa-2x"></i>
          )}
        </div>
      </div>
      {disclaimer.reponse ? (
        <>
          <div className="disclaimer-accordion-content">
            <div className="disclaimer-accordion-content__first-line">
              <span>Je soussigné(e) </span>
              <input
                disabled={disabled}
                type="text"
                placeholder="Nom Prénom"
                value={disclaimer.nom}
                onChange={(e) => {
                  setDisclaimerUserInput("nom", e.target.value);
                }}
              />
            </div>
            <span>
              déclare assumer pleinement les responsabilités des dommages
              éventuellement causés au(x) produit(s):
            </span>
            <textarea
              disabled={disabled}
              placeholder="Lister les produits"
              value={disclaimer.produits}
              onChange={(e) =>
                setDisclaimerUserInput("produits", e.target.value)
              }
              rows={3}
            />
            <span>
              et à l&apos;environnement (escalier, mur, plafond, etc...), et
              m&apos;engage à conserver le(s) produit(s) en l&apos;état.
            </span>
            <div className="disclaimer-accordion-content__last-line">
              <b>
                Je dégage ainsi toute responsabilité de la société de livraison
                et du magasin.
              </b>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

Disclaimer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  disclaimer: PropTypes.object.isRequired,
  toggleDisclaimer: PropTypes.func.isRequired,
  setDisclaimerUserInput: PropTypes.func.isRequired,
};

export default Disclaimer;
