import React from "react";
import PropTypes from "prop-types";

export const HalfdaySwitch = ({
  selectedHalfday,
  setSelectedHalfday,
  canBeNotSelected = false,
}) => {
  const handleSelected = (halfday) => {
    if (canBeNotSelected) {
      halfday === selectedHalfday
        ? setSelectedHalfday(null)
        : setSelectedHalfday(halfday);
    } else {
      setSelectedHalfday(halfday);
    }
  };

  return (
    <div className="halfday-switch">
      <div
        className={`halfday-switch__item ${
          selectedHalfday === "am" ? "selected" : ""
        }`}
        onClick={() => {
          handleSelected("am");
        }}
      >
        Matin
      </div>
      <div
        className={`halfday-switch__item ${
          selectedHalfday === "pm" ? "selected" : ""
        }`}
        onClick={() => {
          handleSelected("pm");
        }}
      >
        Après-midi
      </div>
    </div>
  );
};

HalfdaySwitch.propTypes = {
  setSelectedHalfday: PropTypes.func.isRequired,
  selectedHalfday: PropTypes.string,
  canBeNotSelected: PropTypes.bool,
};

export default HalfdaySwitch;
