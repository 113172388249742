import React from "react";
import PropTypes from "prop-types";
import QualitySheetFormSection from "./QualitySheetFormSection";
import Radio from "components/Radio";

const actions = {
  controle_emballage: "Contrôle emballage",
  deballage_effectue: "Déballage effectué",
  reprise_emballage: "Reprise emballage",
};

const InterventionsTable = ({ interventions, setIntervention }) => {
  return (
    <QualitySheetFormSection
      title="Action des livreurs"
      rows={Object.entries(actions).map(([actionKey, action]) => {
        const isDoneIntervention = interventions[actionKey];

        return (
          <div key={action}>
            {action}
            <Radio
              label="Oui"
              checked={isDoneIntervention === true}
              onChange={() => setIntervention(actionKey, true)}
            />
            <Radio
              label="Non"
              checked={isDoneIntervention === false}
              onChange={() => setIntervention(actionKey, false)}
            />
          </div>
        );
      })}
    />
  );
};

InterventionsTable.propTypes = {
  interventions: PropTypes.object.isRequired,
  setIntervention: PropTypes.func.isRequired,
};

export default InterventionsTable;
