import React from "react";
import PropTypes from "prop-types";

import Compliance from "./Sections/Compliance";
import PackageCount from "./Sections/PackageCount";
import SatisfactionTable from "./Sections/SatisfactionTable";
import InterventionsTable from "./Sections/InterventionsTable";
import NonInstallation from "./Sections/NonInstallation";
import SecurityCheck from "./Sections/SecurityCheck";
import Signature from "./Sections/Signature";
import Confirmation from "./Sections/Confirmation";
import ErrorMessage from "./Validation/ErrorMessage";
import BypassQualitySwitch from "./Sections/BypassQualitySwitch";
import StoreLogo from "components/StoreLogo";

const QualitySheet = ({
  articles,
  errors,
  qualitySheet,
  hasSAV,
  actions,
  submitButton,
  someOrderArticlesAreNotDelivered,
}) => {
  const {
    isCompliant,
    bypass,
    nombre_colis,
    satisfaction,
    intervention,
    decharge_responsabilite,
    non_installation,
    signature,
    confirmation,
    security,
  } = qualitySheet;

  const {
    setIsCompliant,
    setBypassReason,
    setPackageCount,
    setSatisfaction,
    setIntervention,
    setSecurity,
    toggleNonInstallation,
    setNonInstallationReason,
    setSignature,
    toggleConfirmation,
    toggleBypass,
    setShowQualitySheet,
  } = actions;

  const articleCategories = React.useMemo(
    () => articles.map(({ article }) => article.category?.name),
    [articles]
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setSignature(null);
    if (confirmation) {
      toggleConfirmation();
    }
    //TODO fix my dependencies
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (someOrderArticlesAreNotDelivered && isCompliant) {
      setIsCompliant(false);
    }
  }, [someOrderArticlesAreNotDelivered, setIsCompliant, isCompliant]);

  return (
    <div className="quality-sheet">
      <div className="quality-sheet__content">
        <div className="quality-sheet__top-container">
          <button
            className="back-button"
            type="button"
            onClick={() => setShowQualitySheet(false)}
          >
            <i className="fa fa-chevron-left" /> Retour
          </button>

          <StoreLogo />
        </div>

        <div className="quality-sheet__title">
          Fiche de livraison / levée des réserves
        </div>

        <p className="quality-sheet__guidance">
          Tous les champs sont obligatoires, à l&apos;exception de la section «
          Non installation ».
        </p>

        <form className="quality-sheet-form">
          <ErrorMessage errors={errors && errors.nombre_colis} />
          <PackageCount
            packageCount={nombre_colis}
            setPackageCount={setPackageCount}
          />

          <ErrorMessage errors={errors && errors.isCompliant} />
          <Compliance
            isCompliant={someOrderArticlesAreNotDelivered ? false : isCompliant}
            setIsCompliant={setIsCompliant}
            disabled={hasSAV || someOrderArticlesAreNotDelivered}
          />

          {bypass.reponse && <ErrorMessage errors={errors && errors.bypass} />}
          <BypassQualitySwitch
            bypass={bypass}
            toggleBypass={toggleBypass}
            setBypassReason={setBypassReason}
          />

          {!bypass.reponse && (
            <>
              <ErrorMessage errors={errors && errors.satisfaction} />
              <SatisfactionTable
                satisfaction={satisfaction}
                setSatisfaction={setSatisfaction}
              />

              <ErrorMessage errors={errors && errors.intervention} />
              <InterventionsTable
                interventions={intervention}
                setIntervention={setIntervention}
              />

              {non_installation.reponse && (
                <ErrorMessage errors={errors && errors.non_installation} />
              )}
              <NonInstallation
                nonInstallation={non_installation}
                toggleNonInstallation={toggleNonInstallation}
                setNonInstallationReason={setNonInstallationReason}
              />

              {!non_installation.reponse && (
                <div>
                  <ErrorMessage errors={errors && errors.security} />
                  <SecurityCheck
                    security={security}
                    setSecurity={setSecurity}
                    articleCategories={articleCategories}
                  />
                </div>
              )}

              {decharge_responsabilite.reponse && (
                <ErrorMessage
                  errors={errors && errors.decharge_responsabilite}
                />
              )}

              <ErrorMessage errors={errors && errors.confirmation} />
              <Confirmation
                confirmation={confirmation}
                toggleConfirmation={toggleConfirmation}
              />

              <ErrorMessage errors={errors && errors.signature} center />
              <Signature signature={signature} setSignature={setSignature} />
            </>
          )}
        </form>
        {submitButton ?? null}
      </div>
    </div>
  );
};

QualitySheet.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      article: PropTypes.shape({
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }).isRequired
  ).isRequired,
  errors: PropTypes.shape({
    signature: PropTypes.array,
    confirmation: PropTypes.array,
    decharge_responsabilite: PropTypes.array,
    security: PropTypes.array,
    non_installation: PropTypes.array,
    bypass: PropTypes.array,
    satisfaction: PropTypes.array,
    nombre_colis: PropTypes.array,
    isCompliant: PropTypes.array,
    intervention: PropTypes.array,
  }),
  qualitySheet: PropTypes.shape({
    isBypassed: PropTypes.bool,
    bypass: PropTypes.object.isRequired,
    isCompliant: PropTypes.bool,
    nombre_colis: PropTypes.string,
    satisfaction: PropTypes.object.isRequired,
    intervention: PropTypes.object.isRequired,
    decharge_responsabilite: PropTypes.object.isRequired,
    non_installation: PropTypes.object.isRequired,
    signature: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    confirmation: PropTypes.bool.isRequired,
    security: PropTypes.object.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    setIsCompliant: PropTypes.func.isRequired,
    setPackageCount: PropTypes.func.isRequired,
    setSatisfaction: PropTypes.func.isRequired,
    setIntervention: PropTypes.func.isRequired,
    setSecurity: PropTypes.func.isRequired,
    toggleDisclaimer: PropTypes.func.isRequired,
    toggleBypass: PropTypes.func.isRequired,
    setBypassReason: PropTypes.func.isRequired,
    setDisclaimerUserInput: PropTypes.func.isRequired,
    toggleNonInstallation: PropTypes.func.isRequired,
    setNonInstallationReason: PropTypes.func.isRequired,
    setSignature: PropTypes.func.isRequired,
    toggleConfirmation: PropTypes.func.isRequired,
    setShowQualitySheet: PropTypes.func.isRequired,
  }).isRequired,
  hasSAV: PropTypes.bool.isRequired,
  someOrderArticlesAreNotDelivered: PropTypes.bool.isRequired,
  submitButton: PropTypes.node,
};

export default QualitySheet;
