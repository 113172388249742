import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ErrorMessage = ({ errors = null, error = null, center = false }) => {
  if (error) {
    return (
      <div
        className={classNames("quality-sheet-form__error", {
          "quality-sheet-form__error--center": center,
        })}
      >
        {error}
      </div>
    );
  }

  return (
    <ul
      className={classNames("quality-sheet-form__error", {
        "quality-sheet-form__error--center": center,
      })}
    >
      {errors &&
        errors
          .filter((error) => !!error)
          .map((error) => <li key={error}>{error}</li>)}
    </ul>
  );
};

ErrorMessage.propTypes = {
  errors: PropTypes.array,
  error: PropTypes.string,
  center: PropTypes.bool,
};

export default ErrorMessage;
