import { gql } from "@apollo/client";
import { ROADMAP_FRAGMENT } from "pages/Round/roadmapQuery";
import getKeyByValue from "utils/getKeyByValue";

export const UPDATE_ROADMAP_POSITIONS = gql`
  ${ROADMAP_FRAGMENT}

  mutation updateRoadmapPositions(
    $code: ID!
    $positions: [RoadmapPositionInput]!
  ) {
    updateRoadmapPositions(code: $code, positions: $positions) {
      success
      roadmap {
        ...RoadmapFragment
      }
    }
  }
`;

export const makeUpdatePositionOptions = (roadmap, positions) => ({
  variables: {
    code: roadmap.code,
    positions: [
      ...Object.keys(positions.am).map((position) => ({
        round_line_position: Number(position),
        id: positions.am[position],
        type: positions.am[position].includes("_-_") ? "Contractor" : "Order",
      })),
      ...Object.keys(positions.pm).map((position) => ({
        round_line_position: Number(position),
        id: positions.pm[position],
        type: positions.pm[position].includes("_-_") ? "Contractor" : "Order",
      })),
    ],
  },
  context: {
    offline: true,
  },
  optimisticResponse: {
    __typename: "Mutation",
    updateRoadmapPositions: {
      __typename: "UpdateRoadmapPositionsMutationResponse",
      success: true,
      roadmap: {
        __typename: "Roadmap",
        id: roadmap.id,
        am: roadmap.am.map((item) => {
          if (item.__typename === "RoadmapOrder") {
            return {
              ...item,
              order: {
                ...item.order,
                round_line_position: Number(
                  getKeyByValue(positions.am, item.id)
                ),
              },
            };
          }

          return {
            ...item,
            round_line_position: Number(getKeyByValue(positions.am, item.id)),
          };
        }),
        pm: roadmap.pm.map((item) => {
          if (item.__typename === "RoadmapOrder") {
            return {
              ...item,
              order: {
                ...item.order,
                round_line_position: Number(
                  getKeyByValue(positions.pm, item.id)
                ),
              },
            };
          }
          return {
            ...item,
            round_line_position: Number(getKeyByValue(positions.pm, item.id)),
          };
        }),
      },
    },
  },
});
