import { useContext, createContext, useState } from "react";
import { useHistory } from "react-router-dom";

const KEY = "CURRENT-USER";

const isLocalStorageAvailable = () => {
  return window && window.localStorage;
};

const guardAgainstUnavailableLocalStorage = (callback) => {
  if (!isLocalStorageAvailable()) {
    throw new Error("The localStorage is not available");
  }

  return callback;
};

const getPersistentUser = guardAgainstUnavailableLocalStorage(() => {
  return JSON.parse(window.localStorage.getItem(KEY) || null);
});

const setPersistentUser = guardAgainstUnavailableLocalStorage((newCode) => {
  return window.localStorage.setItem(KEY, JSON.stringify(newCode));
});

const removePersistentUser = guardAgainstUnavailableLocalStorage(() => {
  return window.localStorage.removeItem(KEY);
});

const removePersistentAdditionalContractorsDate = guardAgainstUnavailableLocalStorage(
  () => {
    return window.localStorage.removeItem("ADDITIONAL-DATES");
  }
);

const AuthContext = createContext({
  loginCode: null,
  setLoginCode: () => {},
});

const useAuthState = () => {
  return useState(getPersistentUser);
};

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);
  const today = new Date();
  const tokenDate = new Date(user?.login_at.split(" ")[0]);
  const history = useHistory();

  if (user && today.toDateString() !== tokenDate.toDateString()) {
    setUser(null);
    removePersistentUser();
    history.push("/");
  }

  return {
    user,
    login: (user) => {
      setUser(user);
      setPersistentUser(user);
    },
    logout: () => {
      setUser(null);
      removePersistentUser();
      removePersistentAdditionalContractorsDate();
    },
  };
};

export { AuthContext, useAuthState };

export default useAuth;
