import React from "react";
import { Route, Link } from "react-router-dom";
import PropTypes from "prop-types";

import useAuth from "utils/useAuth";
import useAppTitle from "utils/useAppTitle";
import useNetworkStatus from "utils/useNetworkStatus";

const HeaderContent = ({ title, isHome }) => (
  <h2 className="title">
    {isHome ? (
      <>
        <Link to="/settings" className="header-button right">
          <button className="icon-button">
            <i className="fa fa-cogs" />
          </button>
        </Link>
        {title}
      </>
    ) : (
      <>
        <Link to="/" className="header-button right">
          <button className="icon-button">
            <i className="fa fa-times" />
          </button>
        </Link>
        {title}
      </>
    )}
  </h2>
);

HeaderContent.propTypes = {
  title: PropTypes.string,
  isHome: PropTypes.bool,
};

const Header = () => {
  const { user } = useAuth();
  const { title } = useAppTitle();
  const isOnline = useNetworkStatus();

  return (
    <header className="app-header">
      <div className="left">
        {!isOnline ? (
          <span className="fa-stack fa-1x">
            <i className="fa fa-wifi fa-stack-1x fa-inverse"></i>
            <i
              className="fa fa-ban fa-stack-2x"
              style={{ color: "Tomato" }}
              title="Vous êtes déconnecté"
            ></i>
          </span>
        ) : (
          <i className="fa fa-wifi fa-inverse" title="Vous êtes connecté"></i>
        )}
      </div>
      {user && (
        <Route
          exact
          path="/"
          render={() => <HeaderContent title="Ma tournée" isHome={true} />}
        />
      )}
      <Route
        exact
        path="/delivery/:delivery_id"
        render={() => <HeaderContent title={title} />}
      />
      <Route
        exact
        path="/picking/:picking_id"
        render={() => <HeaderContent title="Picking" />}
      />
      <Route
        exact
        path="/settings"
        render={() => <HeaderContent title="Paramètres" />}
      />
    </header>
  );
};

export default Header;
